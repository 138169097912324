.dbr-report-params-form {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dbr-fieldset {
    border: none;

    & > * {
      margin-bottom: 0.75rem;
    }

    .input-wrapper {
      padding: 0.25rem 0;

      .input-label {
        display: grid;
        column-gap: 0.5rem;
        grid-template-columns: 180px minmax(auto, 290px) 80px;
        align-items: center;

        &.select-label {
          grid-template-columns: 180px minmax(auto, 370px);
        }
      }
    }
  }

  .modal-actions {
    margin-top: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      max-height: 2.5rem;
    }
  }

  &.report-5-4-params-form {
    min-height: 350px;
  }

  &.report-5-7-params-form {
    min-height: 375px;

    .dbr-fieldset {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 4rem;

      .input-wrapper:nth-child(n + 3) {
        grid-column: 1 / span 2;
      }

      .input-label {
        &.select-label {
          grid-template-columns: 180px minmax(auto, 572px);
        }
      }

      .date-label {
        grid-template-columns: 120px minmax(auto, 290px);
      }
    }
  }
}

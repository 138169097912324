h1 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3rem;
  color: $dark-font;
  font-family: 'Open-Sans', sans-serif;
  letter-spacing: normal;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3rem;
  color: $dark-font;
  font-family: 'Open-Sans', sans-serif;
  letter-spacing: normal;
}

h3 {
  font-family: 'Open-Sans', sans-serif;
  color: $dark-font;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 1.25rem;
}

h4 {
  font-family: 'Open-Sans', sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: $dark-font;
}

h6 {
  font-family: 'Open-Sans', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: $dark-font;
}

p {
  font-size: 15px;
  color: $dark-font;
  font-family: 'Open-Sans', sans-serif;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}

span {
  font-size: 0.9375rem;
  color: $dark-font;
  font-family: 'Open-Sans', sans-serif;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
}

span strong {
  font-size: 0.9375rem;
  color: $dark-font;
  font-family: 'Open-Sans', sans-serif;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
}

label {
  font-family: 'Open-Sans', sans-serif;
}

@import '../../../style/colors.scss';

.cflow-dropdown-toggle-btn {
  display: flex;
  align-items: center;

  .black-button {
    input {
      border: none;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.9375rem;
      font-weight: 600;
      background-color: transparent;
    }
  }

  .black-button.invalid {
    border-color: $input-border-invalid !important;
  }

  label {
    display: block;
    width: 30%;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .big-label {
    margin-bottom: 0.5rem;
    display: block;
    width: 100% !important;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding: 4px 8px;
    width: 100%;
    height: 32px;
    border-radius: 0;
    border: 1px solid #e4e9f2;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(206, 203, 203, 0.281);
      border: 1px solid #e4e9f2;
    }

    .cflow-dropdown-caption {
      display: flex;
      margin-left: 10px;
      align-items: center;
      width: 96%;
      overflow: hidden;

      .placeholder {
        color: #9fabbf;
        font-size: 12px;
        max-width: 85%;
      }

      svg {
        margin-right: 5px;
        transform: scale(0.8);
      }

      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.5rem;
        color: #222b45;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.cflow-dropdown-search-wrapper {
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: transparent;

  input {
    width: 100%;
    border: none;

    &::placeholder {
      color: #9fabbf;
    }
  }
}

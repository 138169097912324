.cflow-empty-data-placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 200px;
    max-height: 200px;
  }

  span {
    display: block;
    font-size: 24px;
    margin-top: 32px;
    text-align: center;
    strong {
      color: #afbfc7;
    }
  }
}

.cflow-logistic-page .welcome-screen-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;

  & > * {
    padding-top: 1rem;
  } 

  h3.welcome-screen-title {
    font-size: 30px;
  }

  span.welcome-screen-subtitle {
    margin-top: 10px;
  }

  .links {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .link {
    width: 100%;
    border-left: 5px solid #06503c;
    padding: 15px 40px;
    color: #000;
    font-family: 'Open-Sans', sans-serif;
    text-decoration: none;
    font-size: 20px;
    background-color: #eee;
    transition: background-color 0.2s ease 0s;

    &:hover {
      background-color: rgb(218, 218, 218);
    }
  }
}

.blank-delivery-page-edit-package {
  padding-bottom: 20px;
  .column {
    width: 100%;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      column-gap: 20px;

      span {
        white-space: nowrap;
      }
      span.invalid {
        color: #ff3d71;
      }
    }
  }
}

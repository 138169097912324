.logistic-batch-page-plastic-list-modal {
  .logistic-table {
    max-height: 230px;
    min-height: 230px;
  }

  .modal-footer-without-buttons {
    flex-wrap: nowrap;

    .print-button-wrapper {
      margin-left: 0;

      button {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

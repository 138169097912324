@import './colors.scss';

.cflow-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 80px;
  height: 144px;
  width: 292px;
  border: 2px solid #06503c;
  opacity: 0.4;
  background-color: transparent;
  transition: all ease-out 0.35s;

  &.lang-uk.uk:hover,
  &lang-ru.ru:hover,
  &:hover {
    box-shadow: 0 6px 32px 0 rgba(0, 0, 0, 0.15);
    border: 2px solid transparent;
    background-color: $yellow;
    opacity: 1;
  }

  &.lang-uk {
    margin-right: 50px;
  }
}

button:not(.Toastify__close-button) {
  width: 100%;
  padding: 0.8125rem 1.125rem;
  line-height: 1.25rem;
  cursor: pointer;
  background-color: $green;
  border: 0.0625rem solid $green;
  border-radius: 0.25rem;
  transition: all 0.35s;

  span {
    font-size: 1rem;
    font-weight: 700;
    color: #ffffff;
  }

  &.primary {
    background-color: $primary-500;
  }

  &.cancel {
    background-color: $light-400;
    color: $dark-font;
  }

  &.add-multiselect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    text-transform: none;
    text-align: left;
    background-color: transparent;
    color: $dark-font;

    p {
      white-space: nowrap;
      margin-right: 0.5rem;
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:disabled {
    cursor: not-allowed;
    touch-action: none;
    pointer-events: none;
    background-color: rgba(143, 155, 179, 0.24);
    border-color: rgba(143, 155, 179, 0.24);
    color: rgba(143, 155, 179, 0.48);

    p,
    span {
      color: rgba(143, 155, 179, 0.48);
    }
  }
}

//icon btn
.cflow-icon-btn {
  button {
    border: none;
    background-color: transparent;
    transition: none !important;

    .cflow-icon {
      margin-right: 0;
    }

    &:hover {
      svg {
        stroke: $primary-500;
      }
    }

    &:disabled {
      svg {
        stroke: $light-700;
      }

      .path-item-icon {
        svg path {
          fill: $light-700;
        }
      }
    }
  }
}

.cflow-button {
  &.modal-window-btn-no,
  &.modal-window-btn-yes {
    padding: 0.6875rem 1.125rem;
    background-color: transparent;
    transition: background-color 0.2s ease 0s;
    color: $black;
    text-transform: uppercase;
    border-radius: 0.25rem;
  }

  &.modal-window-btn-no {
    border: 1px solid #aaa;
    &:hover {
      background-color: #eee;
    }
  }

  &.modal-window-btn-yes {
    background-color: #0d493f;
    border: 1px solid #0d493f;
    * {
      color: #fff;
    }

    &:hover {
      background-color: #146356;
    }
  }
}

// contained button
.cflow-button-contained {
  background-color: #06503c;
  transition: background-color 0.2s ease;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #043b2d;
  }

  &:focus {
    outline: 5px solid $input-bg-hover;
  }
}

// modal button
.cflow-button-modal-button {
  padding: 0.6875rem 1.125rem;
  background-color: transparent;
  transition: background-color 0.3s ease;
  border: 1px solid $yellow;
  color: $black;
  text-transform: uppercase;
  border-radius: 0.25rem;

  &:hover {
    background-color: $input-bg-hover;
  }

  &:focus {
    border-color: $dark-font !important;
    background-color: $input-bg-hover !important;
  }
}

.cflow-button-disabled {
  background-color: #aaa !important;
  border: 1px solid #aaa !important;
  color: #eee;
  cursor: not-allowed;

  &:hover {
    background-color: #aaa !important;
    cursor: not-allowed;
  }
}

.cflow-button-modal-button.cflow-button-yes {
  padding: 0.6875rem 1.125rem;
  transition: background-color 0.2s ease 0s;
  text-transform: uppercase;
  border-radius: 0.25rem;
  background-color: #0d493f;
  border: 1px solid #0d493f;
  color: #fff;

  &:hover {
    background-color: #146356;
  }
}

.cflow-button-modal-button.cflow-button-no {
  padding: 0.6875rem 1.125rem;
  background-color: transparent;
  transition: background-color 0.2s ease 0s;
  color: $black;
  text-transform: uppercase;
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  &:hover {
    background-color: #eee;
  }
}

.cflow-button-1 {
  background-color: #506447 !important;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #7b9270;
  }
}

.cflow-button-2 {
  background-color: #fff !important;
  border: 1px solid #506447 !important;
  color: #506447;

  &:hover {
    background-color: #fff;
  }
}

.cflow-button-big {
  padding: 0.6875rem 1.125rem;
  font-size: 15px;
}

.cflow-button-medium {
  padding: 5px 0;
  font-size: 13px;
}

.cflow-anchor-button {
  position: relative;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  color: $dark-font;

  .cflow-anchor-button-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    transform: translateY(0.1rem);
    font-size: 1rem;

    .cflow-anchor-button-content-icon {
      font-size: large;
      margin-right: 0.35rem;
    }
  }

  &:disabled {
    background-color: transparent;
    border: none;
    outline: none;
    color: lightgray;
    cursor: default;
    pointer-events: none;
  }

  &:hover:not(:focus)::before {
    position: absolute;
    bottom: 2.5px;
    left: 0;
    width: calc(100% - 1rem);
    height: 1px;
    margin-left: 1rem;
    background-color: $input-bg-hover;
    content: '';
  }

  &:focus::after {
    position: absolute;
    top: -2.5px;
    left: 0.5rem;
    height: 90%;
    width: 100%;
    content: '';
    border-radius: 3px;
    outline: 2.5px solid $input-bg-hover !important;
    margin-top: 5px;
  }

  &.simple {
    display: inline;
    align-items: unset;
    transform: translateY(0);
    line-height: unset;
    margin: 0 !important;
    padding: 0 !important;

    &:hover:not(:focus)::before {
      content: none;
    }

    &:focus::after {
      content: none;
    }

    .cflow-anchor-button-content {
      transform: translateY(0);
    }

    .cflow-anchor-button-content-icon {
      margin-right: 0;
    }

    .cflow-anchor-button-content-title {
      font-size: 15px;
      transform: translateY(0) !important;
      text-transform: capitalize;
    }
  }
}

@media (max-width: 1024px) {
  .cflow-anchor-button-content-title {
    display: none;
  }

  // .cflow-button-big {
  // 	padding: .6rem .9rem !important;
  // }
}

@media (max-width: 780px) {
  .cflow-anchor-button-content {
    flex-direction: column;
    align-items: start;
  }
}

@mixin table-config {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: 'Open-Sans', sans-serif;
  font-size: 14px;
  padding: 0px 20px;
  background-color: #fff;

  div.left {
    padding: 10px 0;

    span {
      background-color: #eee;
      border: 1px solid rgb(114, 114, 114);
      font-size: 16px;
      border-radius: 5px;
      padding: 5px 15px;
    }
  }

  div.right {
    display: flex;
    align-items: center;
    padding: 5px 0;

    div.pagination {
      display: flex;
      align-self: center;
      justify-content: center;
      margin-right: 10px;

      div {
        color: black;
        padding: 8px 16px;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 2px;
      }

      div.active {
        background-color: #06503c;
        color: white;
        border-radius: 5px;
      }

      div:hover:not(.active) {
        background-color: #ddd;
        border-radius: 5px;
      }

      .disabled {
        background-color: #eee;
        border-radius: 5px;
        cursor: not-allowed;
      }
    }

    span.total-page-count {
      background-color: #eee;
      border: 1px solid rgb(114, 114, 114);
      font-size: 16px;
      border-radius: 5px;
      padding: 5px 15px;
    }
  }
}

.table-select-range-form {
  padding-bottom: 0 !important;
}

.logistic-table-select-range-form {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modal-actions {
    margin-top: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      max-height: 2.5rem;
    }
  }

  fieldset {
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    row-gap: 1rem;

    .input-wrapper:nth-child(n+3){
      grid-column: 1 / span 2;     
      justify-self: start;  
    }

    .input-wrapper:nth-child(1),
    .input-wrapper:nth-child(2) {
      .input-label {
        display: grid;
        column-gap: .5rem;
        grid-template-columns: 30px minmax(auto, 230px);
        align-items: center;
  
        .input-item-label {
          margin-bottom: 0;
        }
      }
    }

    .input-wrapper-mask input {
      text-align: right;
    }

    .input-wrapper.label-right {
      height: 2rem;

      .input-label {
        height: 100%;

        .error-container {
          height: 100%;
          display: flex;
          align-items: center;
  
          input[type='checkbox'] {
            padding: 0;
            width: 1.25rem;
            margin-right: 0.5rem;
          }
        }
      }
    } 
  }
}
.cflow-dropdown-controls{
  display: flex;
  justify-content: space-between;
  padding: .5rem .75rem;
  border-top: 1px solid $light-500;

  button{
    width: auto;
    height: auto;
    padding: .35rem 1rem;
    background-color: transparent;
    border: 1px solid $yellow;
    color: $dark-font;
    //text-transform: uppercase;
    border-radius: 0.25rem;
  }

  button + button{
    margin-left: .75rem;
  }

  .cflow-btn-group-left{

  }

  .cflow-btn-group-right{

  }
}
.create-adjust-blank-select-tarif {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table {
    width: 100%;
    max-height: 400px;
    overflow: auto;
  }
}
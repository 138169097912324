.branch-selector {
  width: 100%;

  .switch-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    border-bottom: 3px solid #dee0e0;
    margin-bottom: 10px;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #bfc9c9;
      padding: 10px 20px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      span {
        color: #a6afaf;
      }
      cursor: pointer;
    }
    .item:not(.active) {
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #bac4c4;
        color: #808686;
      }
    }
    .item.active {
      background-color: #dee0e0;
      span {
        color: #333;
      }
    }
  }

  .back {
    background-color: transparent;
    transition: background-color 0.3s ease;
    border: 1px solid #dee0e0;
    width: auto;
    align-self: start;
    margin-left: 0px;
    svg {
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }

    &:hover {
      background-color: #eee;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  input {
    width: 80%;
    padding: 8px;
    font-size: 15px;
  }

  .div-input {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    svg {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .table {
    width: 100% !important;
    height: 420px;
    overflow: auto;
    border: 1px solid #aaa;
    border-radius: 3px;

    .div-label {
      width: 100%;
      background-color: rgb(250, 239, 220);
      height: calc(350px - 58px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    th, td {
      text-align: left;
    }
  }
}

body{
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

a, button, input{
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    cursor: pointer;
  }
}

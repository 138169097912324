@import '../../../../style/colors.scss';

.cflow-blank-search-area {
  position: relative;
  background-color: $white;
  padding: 0 0.3rem 0.1rem 0.3rem;
  border-radius: 0.25rem;

  .screen-title {
    text-align: center;
    font-size: 1.74rem;
  }

  .hidden-blank {
    width: 100%;
    background-color: rgb(255, 244, 217);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1.7px solid rgb(253, 227, 161);
    border-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;

    cursor: pointer;
    transition: background-color 0.3s ease, border-radius 0.6s ease;

    .attr-label {
      display: flex;
      align-items: center;
      span {
        color: rgb(49, 44, 44);
        font-size: 13px;
      }

      svg {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        color: rgb(49, 44, 44);
        transition: transform 0.6s ease;
      }
    }

    .attr-actions {
      display: flex;
      align-items: center;
      column-gap: 10px;

      button {
        padding: 7px 10px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        column-gap: 10px;
        border-radius: 3px;

        span {
          font-size: 16px;
          font-weight: normal;
        }
      }
      button.search {
        background-color: #004b46;
        // border: 1px solid transparent;

        &:hover {
          background-color: #043b2d;
        }
      }
      button.search.disabled,
      button.cancel.disabled {
        background-color: transparent;
        border: 1px solid #004b46;
        color: #004b46;
        span {
          color: #004b46;
        }
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }
      button.cancel {
        background-color: #506447;
      }
    }

    &:hover {
      background-color: rgb(255, 239, 203);
    }
  }

  .hidden-blank.closed {
    border-radius: 5px;
    border: 1.7px solid rgb(253, 227, 161) !important;

    .attr-label {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

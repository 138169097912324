.cflow-log-table {
    width: 100%;
    font-family: 'Futura-PT-Light', sans-serif;

    thead {
        width: 100%;
        font-size: 14px;

        tr {
            width: 100%;
            background-color: #eee;
            color: rgb(65, 61, 61);

            th {
                padding: 20px 10px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    tbody {
        width: 100%;

        tr {
            width: 100%;

            th {
                padding: 20px 10px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            th.bold {
                p {
                    font-weight: 700;
                }
            }
        }

        tr.error {
            background-color: rgb(252, 243, 243);
            color: rgb(214, 55, 55) !important;

            th.bold {
                p {
                    color: rgb(214, 55, 55) !important;
                }
            }
        }

        tr.warning {
            background-color: rgb(245, 245, 219);
            color: rgb(189, 124, 27) !important;

            th.bold {
                p {
                    color: rgb(189, 124, 27) !important;
                }
            }
        }

        .selecting {
            transition: background-color 0.2s ease;
        }

        .selecting:hover {
            background-color: #cee7e7;
            cursor: pointer;
        }

        .selected {
            background-color: #cee7e7;
        }
    }
}
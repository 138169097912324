.cflow-print-movement-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		text-align: center;
	}



	.cflow-input-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin: 5px 20px;
		label {
			font-size: 14px;
			font-family: 'Open Sans', sans-serif;
			margin-bottom: 3px;
		}
		input {
			padding: 5px 10px;
			border-radius: 3px;
			border: 1px solid black;
			background-color: transparent;
			font-size: 16px;
			color: #000;
		}

		.cflow-dropdown-toggle-btn .black-button {
			height: 39px !important;
		}
	}

	&_header {
		width: 100%;
		padding: 10px 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		border-bottom: 2px solid rgb(152, 184, 194);

		.single-column {
			width: 100%;
		}

		.column {
			width: 50%;

			.double-date-picker {
				margin: 5px 0;
				span {
					font-family: 'Open Sans', sans-serif;
					font-size: 14px !important;
					margin-bottom: 3px !important;
				}

				.datepicker-div {
					margin-bottom: 15px;
				}
			}
		}
	}

	.cflow-input-item {
		input {
			height: 38.8px !important;
			margin-bottom: 0;
		}
	}

	&_footer {
		width: 100%;
		margin-top: 10px;
		padding-bottom: 10px;
		border-bottom: 2px solid rgb(152, 184, 194);
		display: flex;
		flex-wrap: wrap;
		column-gap: 15px;
		row-gap: 10px;
	}
}

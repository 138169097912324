@import '../../../style/colors.scss';

.dashboard-blank-import-data-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.blank-import-data-page {
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .log-table-wrapper {
    table.data-table {
      display: table;
      width: 100%;
    }
  }

  & > .import-history-wrapper {
    flex-basis: 100%;

    th {
      padding: 0.5rem 1rem;
    }
  }

  & > div {
    padding: 0 1rem 1rem 1rem;
    background-color: $white;
    border-radius: 3px;
  }

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

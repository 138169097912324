@import '../../../style/colors.scss';

.dashboard-blank-balance-report-page {
  height: 100%;
}

.dashboard-blank-balance-report {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background-color: $white;

  & > * {
    padding: 1rem 1rem 0;
  }

  & > *:first-child {
    padding-top: 0;
  }

  & > *:last-child {
    padding-bottom: 1rem;
    flex: 1 1 100%;

    display: flex;
    align-items: stretch;
  }

  form {
    display: flex;
    justify-content: flex-start;

    button.cflow-button-contained {
      align-self: flex-end;
      width: auto;
      min-width: 7rem;
      max-height: 2.5rem;
      margin-bottom: 1rem;

      &:focus {
        outline: 5px solid $input-bg-hover;
      }
    }

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
@import '../../style/mixins/default_button.scss';

.cflow-login-wrapper {
  width: calc(100vw - 4rem);
  height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: $white;
}

.cflow-login {
  width: 100%;
  max-width: 35rem;
  text-align: center;

  h1 {
    margin-bottom: 0.75rem;
  }
  h4 {
    margin-bottom: 2rem;
  }

  form {
    text-align: left;

    button.login-button {
      @include default_button;
    }
  }
}

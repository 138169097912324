@import '../../../style/colors.scss';

.cflow-multiselect-wrapper {
  position: relative;
  font-family: 'Open Sans', sans-serif;

  .cflow-multiselect-toggle-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p.placeholder {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: normal !important;
      text-align: left;
      text-transform: lowercase;
    }

    button.black-button {
      color: #222b45 !important;
      border: 1px solid #222b45 !important;
      border-radius: 0.25rem !important;
      padding: 0.4rem;
    }

    label {
      display: block;
      width: 30%;
      margin-bottom: 0;
    }

    .big-label {
      margin-bottom: 0.5rem;
      display: block;
      width: 100% !important;
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
      padding: 4px 8px;
      width: 100%;
      border-radius: 0;
      border: 1px solid #e4e9f2;
      background-color: transparent;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(206, 203, 203, 0.281);
        border: 1px solid #e4e9f2;
      }

      .cflow-multiselect-caption {
        display: flex;
        margin-left: 10px;
        align-items: center;
        width: 96%;
        overflow: hidden;

        .placeholder {
          color: #9fabbf;
          font-size: 12px;
          max-width: 85%;
        }

        svg {
          margin-right: 5px;
          transform: scale(0.8);
        }

        p {
          font-family: 'Open Sans', sans-serif;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5rem;
          color: #222b45;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // width: 85%;
          padding-right: 1rem;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus {
        border-color: $dark-font !important;
        background-color: $input-bg-hover;

        svg {
          path {
            fill: $dark-font;
          }
        }
      }
    }
  }
}

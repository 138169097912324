@import '../../../../../../../style/colors.scss';

.search-params > form {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  & > button {
    width: auto;
    margin-top: 1rem;
  }

  .cflow-dropdown-wrapper {
    margin-top: -4px;
  }

  .cflow-dropdown-toggle-btn .cflow-label {
    font-size: .9rem;
    font-weight: normal;
    color: $dark-font;
  }
}
form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.waiter-form-element-wrapper {
  width: 45%;
  &:last-child {
    width: 100%;
  }
  &.divider {
    width: 100%;
    border-top: 2px solid $green;
  }
  &.userEmail,
  &.userPassword {
    width: 100%;
  }
  &.perso_type_name {
    width: 100%;
  }
  &.perso_type_ext_ref {
    width: 45%;
  }
}

.cflow-dropdown-wrapper {
  .cflow-dropdown-toggle-btn {
    display: block;
    button {
      height: 3rem;
    }
  }
  .cflow-label-left {
    width: auto;
    margin-bottom: 0.5rem;
  }

  .cflow-dropdown-search-options-wrapper {
    left: 0;
  }

  .cflow-dropdown-options li {
    padding: 0.5rem;
  }
}

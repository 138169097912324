@import '../../../../style/colors.scss';

.dashboard-reports-1-1-page,
.dashboard-reports-1-2-page,
.dashboard-reports-1-3-page,
.dashboard-reports-2-1-page,
.dashboard-reports-2-2-page,
.dashboard-reports-2-3-page,
.dashboard-reports-2-4-page,
.dashboard-reports-2-5-page,
.dashboard-reports-2-6-page,
.dashboard-reports-3-2-1-page,
.dashboard-reports-3-2-2-page,
.dashboard-reports-3-2-3-page,
.dashboard-reports-3-5-1-page,
.dashboard-reports-3-5-2-page,
.dashboard-reports-4-1-page,
.dashboard-reports-4-2-page,
.dashboard-reports-4-3-page,
.dashboard-reports-4-5-1-page,
.dashboard-reports-4-5-2-page,
.dashboard-report-blank-movement-page {
  min-height: 100%;
  height: auto;
  display: flex;
}

.dashboard-reports-3-2-1-page,
.dashboard-reports-3-2-3-page {
  .dashboard-reports-page .report-form .perso-branch {
    .any-branch-wrapper {
      flex-basis: 100%;
      padding-right: 0;
    }
  }
}

.dashboard-reports-page {
  .cflow-modal-window {
    width: 600px !important;
    max-height: none !important;

    .cflow-modal-window-content {
      margin-bottom: 0;
    }
  }

  flex: 1 1 100%;
  align-self: stretch;
  padding: 0 0.5rem;
  min-width: 410px;
  background-color: $white;
  display: flex;
  overflow-y: auto;

  .report-header {
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;

    .logo {
      margin-bottom: 0.5rem;

      .report-logo {
        display: block;
        margin-left: auto;
      }
    }

    .departments {
      display: flex;
      justify-content: space-between;

      .department,
      .destDepartment {
        max-width: 45%;
      }
    }

    .department,
    .destDepartment {
      font-size: 0.75rem;
    }
  }

  .report {
    flex: 1 1 100%;
    align-self: stretch;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .report-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;

    &.report-section-grow {
      flex-grow: 1;
    }

    .report-caption {
      text-align: center;

      .perso-type {
        font-size: 10px;
        font-weight: bold;
        text-align: right;
      }

      h2 {
        font-size: 15px;
        line-height: 1.2;

        p {
          line-height: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }

      .branch-name {
        margin-top: 1rem;
      }
    }

    .reports-wrapper {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      overflow-x: auto;
      overflow-y: hidden;

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      table {
        display: table;
        width: 100%;
        margin-bottom: 1rem;
      }

      th {
        .table-column-caption-wrapper {
          justify-content: center;
        }
      }

      .report-blank-movement {
        width: 100%;

        tr.report-blank-movement-global {
          th {
            &:first-of-type {
              width: 80px !important;
            }

            background-color: $bright-green;
            color: $white;
          }
        }
      }

      .report-4-5-1-top,
      .report-4-5-1-bottom {
        display: table;
        width: 100%;

        caption {
          font-size: larger;
          font-weight: bold;
          text-align: left;
        }

        th {
          .table-column-caption-wrapper {
            span {
              width: 100%;
              text-align: center;
            }
          }
        }

        td:nth-child(2) {
          text-align: center;
        }
      }

      & > * {
        flex: 1 1 100%;
      }

      th {
        position: static;
        color: $dark-font;
        font-weight: bold;

        .multi-row-header-cell-list-item:not(:last-child) {
          border-bottom: 1px solid #bbb;
        }

        strong {
          font-weight: bold;
        }

        &:first-of-type {
          width: 45% !important;
        }
      }

      td,
      th {
        background-color: $white;
        border: 1px solid #bbb;
        font-size: 14px;

        span,
        strong {
          font-size: inherit;
        }
      }

      .data-row {
        td {
          text-align: right;

          &:first-child {
            text-align: left;
          }
        }

        &:nth-of-type(even) > td {
          background-color: $light-300;
        }

        &:hover {
          td {
            border: 1px solid $positive-500;
            border-top-width: 1.5px;
            background-color: $positive-200 !important;
          }
        }
      }

      .footer-row > td {
        background-color: #fff;
        font-weight: bold;

        text-align: right !important;

        &:first-child {
          text-align: left;
        }
      }
    }

    .created-by {
      font-size: xx-small;
    }

    &.REPORTS_1_2,
    &.REPORTS_1_3,
    &.REPORTS_2_2 th {
      ul {
        li {
          display: flex;
          justify-content: center;
          align-items: center;

          strong {
            text-align: center;
          }
        }
      }
    }

    td {
      padding: 0 2px !important;
      white-space: nowrap;
      white-space: nowrap;
    }

    th .multi-row-header-cell-list-item {
      height: 1rem !important;
      line-height: 0.95 !important;
      padding: 0 4px !important;

      &:first-child {
        height: 4.25rem !important;
      }
    }

    &.REPORTS_3_4_1 {
      th {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          .table-column-caption-wrapper {
            justify-content: flex-start;
          }
        }

        &:first-of-type {
          width: 40px !important;
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
          max-width: 50px;
        }

        &:nth-of-type(5) {
          max-width: 75px;
        }

        &:nth-of-type(6) {
          max-width: 60px;
        }

        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11),
        &:nth-of-type(12) {
          min-width: 50px;
          max-width: 70px;
          word-break: break-all;
        }

        &:nth-of-type(7) {
          max-width: 65px;
          min-width: 50px;
          word-break: break-all;
        }

        &:nth-of-type(13) {
          min-width: 75px;
          max-width: 100px;
        }
      }

      td {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          text-align: left;
        }
      }
    }

    &.REPORTS_4_2 {
      th {
        padding: 4px !important;

        span {
          line-height: 0.9 !important;
        }
      }
    }

    &.print-copy.REPORTS_1_2,
    &.print-copy.REPORTS_2_2 {
      .report-caption h2 {
        font-size: 12px;
        line-height: 1.1 !important;
      }

      th,
      td {
        font-size: 11px;
      }
    }
  }
}

@font-face {
  font-size: 16px;
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-size: 18px;
  font-family: 'Futura-PT-Demi';
  src: url('/fonts/FuturaPTDemi.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-stretch: normal;
}
@font-face {
  font-size: 18px;
  font-family: 'Futura-PT-Light';
  src: url('/fonts/FuturaPTLight.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-stretch: normal;
}

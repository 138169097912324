.cflow-header-bar-wrapper {
  width: 100vw;
  height: 76px;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  background-color: $white;
  display: flex;
  justify-content: space-between;
}

.cflow-upay-logo {
  h1 {
    cursor: pointer;
    color: $green-logo;
    font-family: 'Futura-PT-Book', sans-serif;
    text-decoration: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: 1.75rem !important;
    white-space: nowrap;
  }
}

.cflow-header-bar-items-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
  padding: 1.25rem;
  background-color: $white;
}

.cflow-upay-menu-toggle {
  margin-top: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
}

.cflow-header-bar-items-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
  padding: 1.25rem 1.25rem 1.25rem 2rem;
  background-color: $green-logo;

  .user-branch {
    margin-right: 10px;
    color: #fff;
    font-weight: 500;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-mode {
    margin-right: 1rem;
    color: orange;
    font-weight: bold;
    text-transform: uppercase;
  }

  .left-wrapper {
    display: flex;
    align-items: center;
    column-gap: 15px;

    .divider {
      height: 20px;
      width: 1px;
      background-color: #fff;
    }
  }

  .right-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;

    .version-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: #eee;
      span.version-label {
        color: rgb(209, 209, 209);
        font-size: 14px;
        display: flex;
        column-gap: 5px;
        background-color: #043f2f;
        padding: 8px 12px 5px 12px;
        cursor: pointer;
        svg {
          font-size: 16px;
        }
        b {
          color: #eee;
        }
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      span.version-date {
        cursor: pointer;
        padding: 5px 12px 8px 12px;
        color: rgb(209, 209, 209);
        font-size: 12px;
        background-color: #043f2f;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.cflow-slogan {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9375rem;
  color: $white;
}

.cflow-user-menu-with-dropdown {
  position: relative;

  .drop-down {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: white;
    z-index: 10000;
    width: 100%;
    min-width: 260px;
    max-height: 600px;
    -webkit-box-shadow: 4px 3px 8px 6px rgba(34, 60, 80, 0.42);
    -moz-box-shadow: 4px 3px 8px 6px rgba(34, 60, 80, 0.42);
    box-shadow: 4px 3px 8px 6px rgba(34, 60, 80, 0.42);
    transition: box-shadow 0.2s ease;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 4px !important;
    }
  }

  .drop-down-title {
    display: flex;
    align-self: center;
    column-gap: 0.5rem;
    padding: 7px 10px;
    background-color: #eee;
    border-bottom: 1px solid rgb(190, 190, 190);
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:not(:last-child) {
      padding-left: 38px;
    }

    &:hover {
      background-color: rgb(228, 228, 228);
    }

    span {
      font-weight: 700;
      font-size: 13px;
      color: #222b45;
    }

    svg {
      margin-left: 5px;
      width: 15px;
      height: 15px;
      transition: transform 0.05s linear;
      margin-top: 1px;

      &.transform-90deg {
        transform: rotate(90deg);
      }
    }

    svg.opened {
      transform: rotate(180deg);
    }
  }

  .drop-down-content-block {
    width: 100%;
    overflow: hidden;

    .item {
      width: 100%;
      padding: 6px 10px;
      padding-left: 3rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid rgb(190, 190, 190);
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgb(245, 245, 245);
      }

      span {
        font-size: 12px;
      }
    }
  }

  .drop-down-content-block.opened {
    max-height: 60px;
  }

  .drop-down-content-block.closed {
    max-height: 0px;
  }

  .drop-down.opened {
    max-height: 600px;
  }

  .drop-down.closed {
    max-height: 0px;
    box-shadow: none;
  }
}

.cflow-user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s linear;
  border-radius: 10px;
  position: relative;

  h4 {
    color: $white;
  }

  &:hover {
    background-color: #043f2f;
  }
}

.cflow-user-short-name-avatar {
  width: 30px;
  height: 30px;
  border: 2px solid $white;
  border-radius: 50%;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
  }
}

.cflow-user-full-name {
  margin-right: 7px;
}

.cflow-arrow-down {
  margin-top: 0.5rem;
  transform: scale(0.75);
}

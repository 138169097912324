.dashboard-branches-page {

  .transit-point-form {
    display: flex;
    flex-direction: column;

    label {
      margin-right: 0.5rem;
    }

    .transit-point {
      margin-bottom: 1rem;
    }

    .branch-collection-wrapper {
      height: 22rem;
    }

    .modal-actions {
      padding-top: 1rem;
      text-align: center;
      
      button:first-child {
        margin-right: 1rem;
      }
  
      button {
        width: auto;
      }
    }
  }
}
.Toastify__toast-container {
  max-width: 800px;
  min-width: 320px;
  width: fit-content;
}

.Toastify__toast {
  max-width: 800px;
  min-width: 320px;
  width: fit-content;
  margin-left: auto;
}

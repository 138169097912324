@import '../../../../../style/colors.scss';

.dashboard-blank-balance-oper-day-oper-log-page {
  height: 100%;
}

.blank-balance-oper-day-oper-log-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  & > div {
    padding: 1rem;
    background-color: $white;
  }

  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    flex-grow: 1;
  }
}
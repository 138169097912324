.cflow-profile-change-password {
  display: flex;
  flex-direction: column;  
  padding-top: 1rem;

  .cflow-profile-change-password-fields {
    flex-grow: 1;
    margin-bottom: 1rem;

    .form-input {
      position: relative;

      .waiter-form-element-wrapper {
        margin-bottom: 1rem;
        padding-top: 1rem;
      }

      .error-message {
        position: absolute;
        top: 0;
        right: 0;
        color: red;
        font-size: smaller;
      }
    }

    .cflow-input-wrapper-block{
      display: flex;
      
      &>label{
        flex-basis: 30%;
      }

      &>input{
        flex-basis: 70%;
      }
    }
  }

  .modal-actions {
    text-align: center;
    
    button:first-child {
      margin-right: 1rem;
    }

    button {
      width: auto;
    }
  }
    
}
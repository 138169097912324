@import '../../../style/colors.scss';

.data-table-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .data-table {
    display: block;
    font-family: Open Sans, sans-serif;
    border-collapse: collapse;
    overflow: auto;
    font-size: smaller;

    &:focus {
      outline: 1px solid $border;
    }

    th {
      position: sticky;
      top: 0;
      z-index: 2;
      padding: 2px 4px;
      text-align: left;
      vertical-align: middle;
      background-color: $green;
      color: $white;

      &.multi-row-header-cell {
        padding: 0;
      }

      .table-column-caption-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .multi-row-header-cell-list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          .multi-row-header-cell-list-item {
            height: 3rem;
            line-height: 2rem;
            overflow: hidden;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $white;
          }
        }
      }

      .data-table-quick-filter {
        padding: 0.25rem 1rem;
        height: 1.5rem;
        width: 100%;
        border-color: $white;
        border-style: none;
        border-radius: 0;
        font-weight: normal;
        cursor: text !important;
        background-color: $white !important;

        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;

        &:hover {
          background-color: $white;
        }
      }
    }

    th span {
      color: inherit;

      strong {
        color: inherit;
      }

      &.cflow-arrow-down svg {
        transform-origin: center;
        transform: rotate(180deg);
      }

      &.cflow-arrow-down.desc svg {
        transform: rotate(0deg);
      }
    }

    th,
    td {
      border: 1px solid $border;
      cursor: default;
      transition: 0.2s all ease-out;

      &.no-wrap {
        white-space: nowrap;
      }

      &.checkbox-cell {
        text-align: center;
      }
    }

    td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    td.row-menu-cell {
      padding: 0 !important;
      text-align: center;
    }

    .data-row:nth-of-type(odd) > td {
      background-color: $white;
    }

    .data-row:nth-of-type(even) > td {
      background-color: $primary-100;
    }

    .data-row {
      td {
        padding: 0.25rem 0.5rem;
        transition: all 0.35s ease-out;
        white-space: nowrap;
      }

      &:hover td {
        background-color: $light-500 !important;
      }
    }

    .data-row.data-row-selected td {
      background-color: $positive-200 !important;
      border: 1px solid $positive-500;

      .data-row-menu-wrapper {
        height: auto;
        width: 1.5rem;
        margin: auto;
        display: flex;
        align-items: center;

        .data-row-menu {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 2rem;
          height: auto;
          cursor: pointer;

          .data-row-menu-items {
            position: absolute;
            right: 100%;
            top: 50%;
            border: 1px solid $border;
            border-radius: 3px;
            background-color: $white;
            font-size: 0.9rem;
            box-shadow: 0 4px 24px 0 rgba(12, 0, 51, 0.3);

            li {
              min-width: 12rem;
              padding: 0.5rem 1rem;
              white-space: nowrap;
              transition: all 0.35s ease-out;

              &:hover {
                background-color: $light-200;
              }

              &:not(:last-child) {
                border-bottom: 1px solid $border;
              }
            }
          }
        }
      }
    }

    .footer-row {
      td {
        padding: 0.25rem 0.5rem;
        transition: all 0.35s ease-out;
      }
    }

    tfoot td {
      font-weight: bold;
      background-color: $light-500 !important;
      border-color: $white;
    }
  }

  &.empty-data {
    display: flex;

    .empty-data-placeholder-wrapper {
      margin: auto;
    }
  }

  .empty-data-placeholder-wrapper {
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    height: 100%;
    padding: 5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .cflow-nodata-icon {
      svg {
        height: 200px !important;
      }
    }
  }
}

@import '../../../../style/mixins/table_config.scss';

.cflow-logistic-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .print-sticker-batch-wrapper {
    position: absolute;
    z-index: -1;
  }

  .print-sticker-batch {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 1px;
    font-size: 12px;

    .batch-card {
      box-sizing: border-box;
      width: 215px;
      height: 378px;
      border: 1px solid #000;
      margin: 1px;
      padding: 4px;
      text-align: left;

      .batch-caption {
        height: 24px;
        padding-bottom: 2px;
        line-height: 10px;

        .batch-name {
          font-size: 8px;
        }

        .batch-date {
          font-size: 8px;
        }
      }

      .batch-cards {
        display: block;
        min-width: 205px;
        max-width: 205px;
        height: 278px;
        border-collapse: collapse;
        font-size: 7px;

        td,
        th {
          padding: 1px 1px 0;
          border: 1px solid #000;
          vertical-align: middle;
        }

        tr {
          &.thead {
            height: 24px;
            background-color: #eee;

            th.cards-caption {
              width: 169px;
              padding: 0 4px;
              font-size: 8px;
            }

            th.cards-id {
              width: 36px;
              max-width: 36px;
              font-size: 8px;
              text-align: center;
              word-break: break-all;
            }
          }

          &.card {
            height: 10px;

            td.bin-code {
              width: 24px;
              text-align: center;
            }

            td.plastic-count {
              text-align: center;
            }
          }

          &.tfoot {
            height: 10px;
            background-color: #eee;
            font-size: 9px;

            td {
              font-style: italic;

              &:last-child {
                text-align: center;
              }
            }
          }
        }
      }

      .qr-code {
        width: 64px;
        height: 64px;
      }
    }

    .package-card {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 211px;
      height: 374px;
      border: 1px solid #000;
      margin: 3px;
      padding: 6px;
      font-size: 12px;
      text-align: center;

      .branch-code {
        margin-bottom: 6px;
        font-size: 10px;
      }

      .package-caption {
        padding-bottom: 2px;
      }

      .branch-name,
      .package-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        margin-bottom: 2px;
      }

      .branch-name {
        font-weight: bold;
      }

      .totals {
        margin-top: auto;
        display: flex;

        .totals-stats {
          margin-right: auto;
          text-align: left;
        }

        .qr-code {
          width: 64px;
          height: 64px;
        }
      }
    }
  }

  h2 {
    text-align: center;
  }

  .search {
    width: 100%;
    background-color: #eee;
    border-radius: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;

    .search-inputs {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      .search-column {
        width: 50%;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        padding: 0 10px;

        &-item {
          width: 100%;
          padding: 4px 7px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          > span {
            color: rgb(63, 58, 58);
            width: 50%;
            display: flex;
            align-items: center;

            svg {
              font-size: 14px;
              margin-right: 10px;
            }
          }

          input {
            padding: 7px 10px;
            width: calc(50% - 20px);
            border: none;
            border: 1px solid rgb(95, 95, 95);
            border-radius: 5px;
            font-size: 14px;
          }

          button.branches {
            padding: 5px 10px;
            width: calc(50% - 20px);
            background-color: rgb(223, 223, 223);
            border: 1px solid rgb(95, 95, 95);
            border-radius: 5px;
            position: relative;
          }

          .close-icon {
            position: absolute;
            right: -10px;
            font-size: 15px;
            top: calc(50% - 5px);
            cursor: pointer;
          }

          .cflow-dropdown-wrapper {
            width: calc(50% - 20px) !important;
          }

          button.branches:hover {
            background-color: #aaa;
            color: #000;
          }
        }

        .small-margin {
          padding: 5px 7px !important;
        }
      }

      .date-item {
        padding: 0 7px;

        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          span {
            font-weight: 600;
            margin-right: 10px;
          }

          .dates {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .dates-item {
              margin-top: 5px;
              margin-right: 20px;

              span {
                font-size: 14px;
                font-weight: normal;
              }

              .cflow-dates-picker {
                margin-top: 2px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .search-checkboxes {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;

      label {
        margin: 5px 30px;
      }
    }

    .search-dates {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 30px;

        & > span {
          display: inline-block;
          margin-bottom: 0.5rem;
        }

        span {
          font-weight: 600;
        }

        .dates {
          .dates-item {
            margin-top: 0px;
            width: 175px;

            span {
              font-size: 14px;
              font-weight: normal;
            }

            .cflow-dates-picker {
              margin-top: 2px;
              margin-bottom: 10px;
            }
          }
        }
      }

      .date-item {
        padding: 0 10px;

        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          span {
            font-weight: 600;
            margin-right: 10px;
          }

          .dates {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .dates-item {
              margin-top: 10px;
              margin-right: 20px;

              span {
                font-size: 14px;
                font-weight: normal;
              }

              .cflow-dates-picker {
                margin-top: 2px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .combo {
      margin: 0;
      flex-wrap: wrap;
      row-gap: 0.5rem;

      .item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: row;
        margin-right: 20px;

        .margin-top-bottom-zero {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      .checkbox {
        span {
          font-weight: normal;
        }
      }

      .dates {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        .dates-item {
          margin: 0 10px;
        }
      }
    }

    .search-big-dropdowns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      margin: 5px 0;
    }

    .drop-down-item {
      display: flex;
      align-items: center;
      padding: 0 17px;
      flex-basis: 50%;
      column-gap: 1rem;

      & > *:first-child {
        width: 175px;
      }

      & > *:last-child {
        flex: 1;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
        flex-basis: 100%;
      }

      span {
        white-space: nowrap;
        margin-right: auto;
      }
    }
  }

  & > .cflow-blank-plastic-page-wrapper {
    height: auto;
  }

  .logistic-table {
    width: 100%;
    margin: 10px 0;
    min-height: 300px;
    overflow: auto;
    background-color: #fff;
    flex: 1 1 100%;
  }

  .logistic-table-config {
    @include table-config;
  }

  .logistic-page-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px 0;

    button {
      width: auto;
    }
  }

  .batch-page-actions {
    padding: 10px 0 !important;
    align-items: flex-start;

    .button-group {
      display: flex;
      flex-direction: column;
    }

    button {
      padding: 10px 5px;
    }
  }

  .logistic-page-actions,
  .batch-page-actions {
    .print-button-wrapper {
      position: relative;
      min-width: 10rem;
      align-self: stretch;

      button {
        width: 100%;
        cursor: pointer;
        background-color: #004b46;
        border: 0.0625rem solid #004b46;
        border-radius: 0.25rem;
        background-color: #004b46;

        p {
          font-family: Arial, sans-serif;
          font-weight: 600;
        }

        &.dropdown-button {
          max-height: 42.5px;
        }

        .cflow-dropdown-caption p {
          font-size: 15px;
          font-weight: normal;
          color: white;
        }

        .drop-down-arrow path {
          fill: white;
        }

        &:hover {
          background-color: #043b2d;
        }

        &:disabled {
          background-color: #575757 !important;
          border: 1px solid #575757 !important;
          color: #eee;
          cursor: not-allowed;

          .drop-down-arrow path {
            fill: #eee;
          }

          .cflow-dropdown-caption p {
            color: #cfcfcf;
          }
        }
      }

      ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
      }
    }
  }

  .package-receive-page-actions {
    .print-button-wrapper {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      .cflow-dropdown-toggle-btn button {
        max-height: 3rem;
      }
    }
  }

  .vp-1r {
    padding-top: 0.525rem !important;
    padding-bottom: 0.525rem !important;
  }

  .vp-2r {
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
  }

  .mb-2p {
    margin-bottom: 2px;
  }
}

.cflow-screen-warning-block {
  background-color: #fff;
  border-radius: 0.25rem;
  margin: 5px 0;
  flex-grow: 0;

  .logistic-page-paragraph-label {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .non-searched {
      color: rgb(206, 77, 77);
      font-weight: 600;
    }
  }
}

.dashboard-blank-logistic-page {
  .print-button-wrapper {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .cflow-dropdown-wrapper {
      .cflow-dropdown-toggle-btn {
        display: block;
        button {
          height: 48px;
          max-height: none !important;
        }
      }
      .cflow-label-left {
        width: auto;
        margin-bottom: 0.5rem;
      }

      .cflow-dropdown-search-options-wrapper {
        left: 0;
      }

      .cflow-dropdown-options li {
        padding: 0.5rem;
      }
    }
  }
}

.dashboard-blank-logistic-batch-page,
.dashboard-blank-package-receive-page {
  .search-dates {
    &.combo {
      padding-top: 1rem;
    }

    .item {
      margin-right: 2rem !important;
    }
  }

  .dates-item {
    max-width: 170px !important;
    margin-right: 0 !important;
    margin-left: 1rem !important;

    .cflow-filter-item-wrapper {
      min-width: 170px;

      .cflow-date-picker-start-date {
        margin-right: unset;
      }
    }
  }
}

.dashboard-blank-logistic-page {
  .search-dates .item {
    margin-right: 1rem !important;

    .cflow-date-picker-start-date {
      margin-right: 0 !important;
    }
  }
}

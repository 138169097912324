.cflow-page-content.dashboard-blank-plastic-page,
.cflow-page-content.dashboard-blank-pin-page {
  height: 100%;

  .cflow-modal-window {
    width: 600px;
  }

  .cflow-multiselect-search-options-wrapper {
    position: absolute;
    z-index: 3;
    width: 560px;
    padding-bottom: 0.5rem;
    border: 1px solid $border;
    box-shadow: 0 0 1rem $border;
    background-color: $white;
    overflow: hidden;

    .cflow-available-options-list-wrapper {
      overflow: auto;
    }
  }

  .cflow-table-wrapper {
    overflow: initial !important;
  }
}

.cflow-blank-plastic-page-wrapper {
  height: 100%;
  background-color: $white;
  padding: 0 0.3rem 0.1rem 0.3rem;
  border-radius: 0.25rem;

  .screen-title {
    text-align: center;
    font-size: 1.74rem;
  }

  .cflow-page-section-header-with-toolbar {
    display: flex;
    align-items: center;
  }

  .cflow-page-section {
    margin-bottom: 1rem;

    .double-date-picker {
      display: flex;
      flex-direction: column;

      .datepickers-row {
        display: flex;
      }

      span.double-datepicker-label {
        font-size: 15px;
        margin-bottom: 0.5rem;
      }
    }

    .datepicker-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 1rem;

      span.datepicker-label {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
    }

    &.last-section {
      margin-top: 1rem;
      margin-bottom: 0;
      background: white;
    }
  }

  .cflow-first-line,
  .cflow-second-line {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .cflow-first-line {
    margin-right: 1rem;
  }

  .cflow-form-wrapper-button {
    margin-right: 1rem;
    margin-bottom: 1rem;

    button {
      padding: 0.5rem;
      text-transform: none;
      background-color: transparent;
      color: $dark-font;
    }
  }

  .cflow-form-wrapper-button:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  .cflow-date-picker-dates-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .cflow-date-picker-start-date {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .cflow-dashboard-datepicker {
    display: flex;
    align-items: center;
    height: 124px;
    padding-left: 40px;
    background-color: $white;

    p {
      font-size: 24px;
      font-family: 'Open-Sans', sans-serif;
    }
  }

  .cflow-date-picker-start-date,
  .cflow-date-picker-end-date,
  .cflow-number-orders {
    position: relative;

    input {
      padding: 10px 0.6rem 10px 1.75rem;
      text-transform: none;
      background-color: transparent;
      color: $dark-font;
      border: 1px solid $dark-font;
      border-radius: 0.25rem;
      font-size: 15px;
    }
  }

  .cflow-date-picker-end-date {
    .DayPicker {
      right: 1rem;
    }
  }

  .DayPicker {
    position: absolute;
    top: 100%;
    z-index: 5;

    .CalendarMonth_caption,
    .DayPicker_weekHeader_li,
    .CalendarDay {
      font-family: 'Futura-PT-Light', sans-serif;
    }
  }

  .cflow-user-actions-controls {
    margin-top: 1.5rem;

    margin-left: 1.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .cflow-clear-filter {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0.25rem;
    margin: 0;
    cursor: pointer;
  }

  .cflow-date-picker {
    position: absolute;
    top: 8px;
    left: 5px;
    padding: 0.25rem;
    margin: 0;
    cursor: pointer;
  }
}

.cflow-filter-item-wrapper {
  width: auto;
  margin-right: 1rem;

  .cflow-filter-item-label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: block;

    span.selected {
      font-weight: bold;
    }
  }

  &.ignore-zero-rest-items {
    width: 100%;
    max-width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;

    .cflow-filter-item-label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
    }

    .cflow-ignore-zero-rest-items {
      margin-left: 0.5rem;
      margin-top: 0.25rem;
    }

    .cflow-form-wrapper-input {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      label {
        margin: -0.25rem 0 0 0.25rem;
      }
    }
  }

  &.control-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-top: 1rem;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.cflow-filter-item-wrapper.plastic-types {
  width: 450px !important;
  max-width: 450px !important;
  min-width: 450px !important;

  button.add-multiselect {
    width: 100%;
  }
}

.cflow-right-justified-flexbox {
  justify-content: flex-end;
}

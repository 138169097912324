.cflow-tooltip-wrapper {
  position: absolute;
  z-index: 20;
  display: none;
  padding: 6px 10px;
  background-color: $green-logo;
  min-height: 32px;
  width: auto;
  max-width: 275px;
  border-radius: 4px;
  box-shadow: 0 4px 24px 0 rgba(12, 0, 51, 0.3);
  transition: all .35s;

  p {
    font-family: 'Futura-PT-Light', sans-serif;
    color: $white;
    line-height: 1.25rem;
    word-wrap: break-word;
  }
}
@import '../../../../../../style/colors.scss';

.logistic-plastic-multi-operations-form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  // border: 1px solid blue;

  .tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .tab-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 2px;

      button {
        width: auto;
        border: 1px solid $dark-font;
        padding-left: 4px;
        padding-right: 4px;
        white-space: nowrap;

        &.active {
          color: $dark-font;
          background-color: $white;
          box-shadow: 0 0 4px $dark-font;
          position: relative;
          z-index: 2;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .tab-panels {
      flex: 1 1 100%;
      position: relative;
      min-height: calc(450px - 2rem);

      .panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 1rem;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        row-gap: 1rem;
        background-color: #fff;
        z-index: 1;

        &.inactive {
          z-index: 0;
          opacity: 0;
        }

        & > *:only-child:not(.branch-selector) {
          position: absolute;
          top: 2rem;
        }

        .branch-selector {
          display: flex;
          flex-direction: column;
          height: calc(100% - (1rem + 20px));

          .header + .table {
            height: auto;
          }

          .div-input + .table {
            height: auto;
          }
        }

        .bool-input {
          display: flex;
          column-gap: 1rem;
          align-items: center;
        }

        .date-input {
          display: flex;
          column-gap: 1rem;
          align-items: center;

          .cflow-dates-picker input {
            width: 250px;
          }
        }

        .string-input {
          width: 100%;
          display: flex;
          column-gap: 1rem;
          align-items: center;
      
          label {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            margin-bottom: 5px;
          }
      
          input {
            flex-grow: 1;
            border-radius: 3px;
            padding: 8px 10px;
            font-size: 16px;
            border: 1px solid rgb(80, 73, 73);
          }
        }

        .input-item-label {
          font-weight: bold;
        }
      }
    }
  }

  .comment {
    display: flex;
    flex-direction: column;

    input {
      padding: 8px;
      border-radius: 4px;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
  }

  label {
    margin-bottom: 0.5rem;
    
    &.invalid {
      color: $negative-500;
    }
  }

  input.invalid {
    border: 1px solid $negative-500;
  }
}
.cflow-available-options-list-wrapper {
  overflow: scroll;
  max-height: 370px;
  border-top: 1px solid $light-500;

  li {
    //text-transform: capitalize;
    border-bottom: 1px solid $light-500;
    border-left: none; //3px solid transparent;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    label {
      p {
        font-size: .9375rem;
      }
    }


    ul li label {
      padding: .5rem 0 .5rem 2rem;
      margin-bottom: 0;

      p {
        font-size: .8rem;
      }
    }


    &:last-child {
      border-bottom: none;
    }

    &.selected {
      border-left: none; //3px solid $primary-500;
      font-weight: bold;
    }
  }

  ul>li label {
    &:hover {
      cursor: pointer;
      background-color: $light-200;
    }
  }

}
@import '../../../../../style/colors.scss';

.data-row.blank-movement-parent-row{
  &:nth-of-type(even) > td {
    background-color: unset !important;
  }

  &.data-row-selected {
    td {
      background-color: $positive-200 !important;
    }
  }
}

.data-row.blank-movement-child-row:not(.data-row-selected) {
  &:nth-of-type(even) > td {
    background-color: unset !important;
  }
  
  td:nth-child(3) ~ td {
    background-color: $light-300 !important;
  }
}
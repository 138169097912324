.cflow-available-options-item-wrapper{
  display: flex;
  align-items: center;
  padding: .5rem .7rem;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  height: 46px;

  p{
    font-size: .8rem;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input{
    margin-right: .5rem;
  }

  &.cflow-uppercase-caption{
    p{
      text-transform: capitalize;
    }
  }
  &.true{
    p{
      font-weight: bold;
    }
  }
}
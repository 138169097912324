@import '../../style/colors.scss';

.rangedatepicker-wrapper {
  display: flex;
  flex-wrap: wrap;

  .range-input-wrapper {
    position: relative;
    transition: all 0.35s ease-out;

    &.invalid input[type='text'] {
      border-color: $input-border-invalid;
    }

    .range-datepicker-date-icon-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      margin-left: 0.6rem;
      display: flex;
      align-items: center;
    }

    .range-datepicker-clear-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &:hover > input[type='text'] {
      background-color: $input-bg-hover;
    }
  }

  & > * {
    width: auto;
    padding: 0;
  }

  label {
    display: block;
    margin: 0.5rem 0;
    padding-left: 0.5rem;
  }

  input[type='text'] {
    max-height: 2.5rem;
    width: auto;
    padding-left: 2rem;
    background-color: transparent;

    &:focus {
      background-color: $input-bg-hover;
    }
  }

  .range-start-date.range-item-row,
  .range-end-date.range-item-row {
    label {
      text-align: right;
    }
  }

  .range-start-date,
  .range-end-date {
    position: relative;
    margin-bottom: 1rem;

    label {
      width: 5rem;
    }

    .DayPicker {
      margin-left: 1px;
      position: absolute;
      z-index: 3;
      font-family: Open Sans, sans-serif;

      div {
        font-size: 1rem;
      }
    }

    label {
      margin-right: 1rem;
    }
  }

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  .range-item-row {
    display: flex;
    flex-direction: row;

    .DayPicker {
      margin-top: 2.6rem;
      margin-left: 6.1rem;
    }
  }

  .range-item-column {
    display: flex;
    flex-direction: column;

    .DayPicker {
      margin-top: 4.7rem;
    }
  }
}

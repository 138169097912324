.logistic-page-plastic-multi-operations,
.logistic-page-plastic-operations,
.logistic-page-plastic-logistic {
  .table {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    border: 1px solid rgb(163, 163, 163);
    border-radius: 5px;
  }
}

.logistic-page-plastic-multi-operations {
  padding-bottom: 0 !important;

  .table {
    height: 400px;
    margin-bottom: 1rem;
  }

  .cflow-static-table tr {
    cursor: pointer;
  }

  .cflow-static-table thead tr th.th-select-all {
    height: auto;
  }

  .cflow-static-table tbody tr th {
    padding: 10px;

    label input[type='checkbox'] {
      margin-right: 0 !important;
    }
  }

  .modal-actions {
    display: flex;
    column-gap: 1rem;
  }
}

.logistic-page-plastic-logistic {
  .table {
    height: 400px;

    .cflow-static-table tbody tr th {
      padding: 10px;
    }
  }
}
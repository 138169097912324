.oper-day-edit-operation {
    padding-bottom: 0 !important;
  
    .oper-day-edit-operation-form {
        min-height: 250px;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        justify-content: space-between;
    
        .modal-actions {
            margin-top: auto;
            display: flex;
            justify-content: center;
            gap: 1rem;
    
            button {
                max-height: 2.5rem;
            }
        }
    
        fieldset {
            border: none;
            display: grid;
            justify-items: center;
            row-gap: 1.5rem;
    
            .input-wrapper {
                .input-label {
                    display: grid;
                    column-gap: .5rem;
                    grid-template-columns: 200px minmax(auto, 230px);
                    align-items: center;
        
                    .input-item-label {
                        margin-bottom: 0;
                    }
                }
            }
    
            .input-wrapper-mask input {
                text-align: right;
            }
    
            .input-wrapper.label-right {
                height: 2rem;
    
                .input-label {
                    height: 100%;
        
                    .error-container {
                        height: 100%;
                        display: flex;
                        align-items: center;
            
                        input[type='checkbox'] {
                            padding: 0;
                            width: 1.25rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            } 
        }
    }
}
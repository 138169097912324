.cflow-additional-user-actions-toggle-btn {
  button {
    position: absolute;
    top: 6px;
    left: auto;
    text-transform: capitalize;
    width: auto !important;
    padding: 0.25rem;
    height: auto !important;
    border: none;
    background: transparent;

    .cflow-icon {
      margin-right: 0;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.cflow-dropdown-search-options-wrapper {
  position: absolute;
  left: auto;
  // min-width: 200px;
  right: 5rem;
  z-index: 10;

  &.collapsed {
    display: none;
  }
}

.cflow-dropdown-options {
  position: fixed; //absolute;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  border: 1px solid $border;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;

  li {
    display: flex;
    align-items: center;
    padding: 4px 5px 4px 12px;
    text-transform: capitalize;
    border-bottom: 1px solid $border;
    border-left: 3px solid transparent;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    p {
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg.cflow-delete-item-icon {
      path {
        fill: $dark-font !important;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
      background-color: $input-bg;
    }
  }
}

.cflow-dropdown-search-wrapper {
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: transparent;

  input {
    width: 100%;
    border: none;

    &::placeholder {
      color: $dropdown-placeholder;
    }
  }
}

.cflow-top-user-actions {
  display: flex;
  position: relative;
  column-gap: 10px;
  margin: 5px 0;

  button:hover svg.cflow-delete-item-icon {
    stroke: $primary-500;
    path {
      fill: $primary-500;
    }
  }

  button:hover ~ .cflow-tooltip-wrapper {
    display: block;
  }
}

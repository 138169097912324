.cflow-order-day-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .item {
        margin: 10px 0;
    }

    .open-day {
        .datepicker-div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 5px;

            span.datepicker-label {
                font-size: 16px;
                margin-bottom: .5rem;
            }
        }
    }

    // .open-day {
    //     display: flex;
    //     width: 100%;
    //     align-items: center;
    //     justify-content: space-between;
    //     .cflow-dates-picker {
    //         margin-top: -10px;
    //     }
    // }
}
.global-user-profile {
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }

  .user-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px;
      margin-left: 20px;
    }

    .content {
      display: flex;
      flex-direction: row;
      border: 2px solid rgb(238, 236, 234);
      border-radius: 3px;
      margin-top: 5px;

      .column {
        width: calc(50% - 10px);
        padding: 0 10px;
      }

      .user-content-item {
        width: 100%;
        margin: 7px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-info-label {
          color: #8f9bb3;
          font-family: 'Open Sans', sans-serif;
          font-size: 0.8rem;
          font-weight: 700;
        }

        .user-info-content {
          width: 50%;
          background-color: red;
          padding: 0.3875rem 0.7rem;
          font-family: 'Open Sans', sans-serif;
          font-size: 0.9375rem;
          font-weight: 600;
          line-height: 1.5rem;
          border: 1px solid #e4e9f2;
          border-radius: 0.25rem;
          background-color: #f7f9fc;
          color: #222b45 !important;
        }

        &.user-info-content-wrapper {
          position: relative;

          & > .user-info-action {
            position: absolute;
            right: 0;
            top: 50%;
            width: auto;
            max-height: 1.7rem;
            padding: 0 5px 10px 5px;
            margin-right: 5px;
            line-height: .8rem;
            font-weight: bold;
            font-size: larger;
            background-color: #fff;
            transform: translateY(-50%);

            &:hover {
              background-color: linen;
            }

            &:active {
              background-color: lightgray;
            }
          }
        }
      }
    }

    .role-description {
      border: 2px solid rgb(238, 236, 234);
      border-radius: 3px;
      margin-top: 8px;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .role-description-main {
        font-style: italic;
        margin: 5px 0;
        font-size: 14px;
      }
    }

    .role-avaliable-operations-title {
      margin-top: 20px;
      text-align: center;
    }

    .role-avaliable-operations {
      margin: 10px 0;
      padding: 3px;
      border: 2px solid rgb(238, 236, 234);
      border-radius: 3px;
      max-height: 300px;
      overflow-y: auto;
    }
  }

  th.checkbox-container {
    input[type='checkbox'] {
      cursor: default !important;
    }

    &:hover {
      background-color: unset !important;
      cursor: default !important;
    }
  }
}

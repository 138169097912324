.cflow-table-wrapper.blank-plastic {
  table {
    border-spacing: 0;
    border-collapse: separate;

    thead {
      tr:nth-child(1) th {
        height: 64px;
      }

      tr:nth-child(2) th {
        position: sticky;
        top: 64px;
        z-index: 1;
      }
    }

    tr {
      svg {
        path {
          fill: transparent;
        }
      }

      &.cflow-row-mode-access-read-only {
        td {
          p {
            color: $light-700;
          }
        }
      }

      &:nth-child(2) th {
        border: 1px solid $input-bg-hover;
      }
    }

    td {
      > div {
        position: relative;
        min-height: 1rem;
      }
    }

    td.readonly {
      &:hover div {
        display: block;
      }
    }
  }

  .cflow-quick-filter {
    padding: 0.25rem 1rem;
    width: 100%;
    border-color: white;
    border-style: none;
  }
}

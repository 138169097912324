@import '../../style/colors.scss';

form {
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: $caption;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.6875rem 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    border: 1px solid $border;
    border-radius: 0.25rem;
    background-color: $input-bg;
    color: $dark-font !important;
    transition: 0.35s all ease-out;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 400;
      color: $dropdown-placeholder;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 400;
      color: $dropdown-placeholder;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 400;
      color: $dropdown-placeholder;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      font-weight: 400;
      color: $dropdown-placeholder;
    }

    &:hover {
      background-color: $input-bg-hover;
      border-color: $input-bg-hover;
    }

    &:focus {
      background-color: $white;
      border-color: $green;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: $input-bg-hover;
      border-color: $input-bg-hover;
    }
  }

  textarea {
    resize: vertical;
  }

  .waiter-form-element-wrapper {
    margin-bottom: 2rem;

    .cflow-plain-div-data p {
      width: 100%;
      padding: 0.6875rem 1rem;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1.5rem;
      border: 1px solid #e4e9f2;
      border-radius: 0.25rem;
      background-color: #f7f9fc;
      color: #222b45 !important;
      cursor: not-allowed;
      transition: 0.35s all ease-out;
    }
  }

  .waiter-form-wrapper-input,
  .cflow-form-wrapper-input {
    &.invalid {
      input,
      textarea,
      button {
        border-color: $input-border-invalid;
      }
    }

    input {
      &:disabled {
        cursor: not-allowed;
      }
    }

    &.checkbox {
      position: relative;

      label {
        margin-left: 20px;
      }

      input[type='checkbox'] {
        position: absolute;
        width: auto;
        left: 0;
        top: 0;
      }
    }
  }

  &.add-new-order-blank-plastic-page,
  &.add-new-order-blank-pin-page {
    .cflow-form-section {
      margin-bottom: 1.5rem;
      min-width: 145px;
      width: 30%;
      max-width: 30%;

      &.plastic-types {
        .cflow-form-element-wrapper.invalid {
          button.add-multiselect {
            border-color: $input-border-invalid;
          }
        }
      }

      &.plastic-types,
      &.order-comments {
        min-width: 100%;
        flex-grow: 1;
      }

      .cflow-form-wrapper-input input {
        padding: 0.38rem;
        min-width: 150px;
      }

      .cflow-date-picker-start-date {
        margin-right: 0;
        input {
          padding-left: 1.75rem;
        }
      }
      .cflow-dropdown-toggle-btn {
        button {
          height: auto;
          padding: 0.38rem 0.5rem;
          border-radius: 0.25rem;
          border-color: $dark-font;
        }
      }
    }

    .cflow-available-options-item-wrapper {
      input {
        width: auto;
      }
    }
  }

  &.balances-types-dict-page,
  &.payment-system-dict-page {
    .cflow-form-section {
      margin-bottom: 1.5rem;
      width: 100%;
    }
    .cflow-dropdown-toggle-btn button {
      max-width: 100%;
    }
    .cflow-dropdown-search-options-wrapper {
      max-width: 100%;
    }
  }
  &.balances-types-dict-balance-operations-page {
    .cflow-form-section {
      &.avail-operations {
        margin-top: 1rem;
      }
    }
    ul {
      margin-top: 1rem;
      max-height: 285px;
      overflow-y: auto;
      li {
        margin-bottom: 1rem;
      }
    }
  }

  &.plastic-types-dict-page {
    .cflow-form-section {
      margin-bottom: 1.5rem;
      &.payment_system_name,
      &.external_ref {
        display: block;
        flex-grow: 1;
        width: 100%;
      }
      .cflow-dropdown-toggle-btn button {
        max-width: 100%;
      }
      &.bank_code {
        display: block;
        width: 100%;
      }
    }
  }
  &.plastic-acc-dict-page {
    .cflow-form-section {
      margin-bottom: 1.25rem;
      &.ccy {
        width: 100%;

        .cflow-dropdown-toggle-btn button {
          max-width: 100%;
        }
      }

      &.status {
        width: 100%;

        .cflow-dropdown-toggle-btn button {
          max-width: 100%;
        }
      }
    }
  }

  &.pin-acc-dict-page {
    .cflow-form-section {
      margin-bottom: 1.25rem;

      &.status {
        width: 41%;

        .cflow-dropdown-toggle-btn button {
          max-width: 100%;
        }
      }
    }
  }
}

form.plastic-types-dict-page input.plastic-type-name {
  min-width: 370px;
}

form.plastic-types-dict-page input.bin-code {
  width: 120px;
}

.logistic-page-plastic-logistic {
  .comment-wrapper {
    padding: 1rem 0;

    &.invalid {
      textarea:not(:disabled) {
        border-color: $input-border-invalid !important;
      }

      textarea:focus {
        outline: 1px solid $input-border-invalid !important;
      }
    }

    label {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 0.5rem;
      font-style: italic;

      &.disabled {
        color: $dropdown-placeholder;
      }
    }

    textarea {
      width: 100%;
      border-radius: 4px;
      padding: 0.5rem;
      resize: none;

      &:focus {
        outline: 1px solid #aaa !important;
      }
    }
  }
}

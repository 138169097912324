.create-adjust-blank-select-tarif-more-info {
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-right: 20px;
        margin-bottom: 1rem;
      }

      input.simple-input {
        padding: 7px 10px;
        border: none;
        border: 1px solid #5f5f5f;
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 1rem;
      }

      .comment {
        width: 400px;
      }

      .cflow-dates-picker {
        margin-bottom: 1rem;
      }
    }
  }
}
.cflow-blank-plastic-movement-page-table,
.cflow-blank-plastic-movement-page-bottom {
  position: relative;
  background-color: $white;
  padding: 0 0.3rem 0.1rem 0.3rem;
  border-radius: 0.25rem;
}

.cflow-blank-plastic-movement-page-actions {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
  padding-right: 20px;

  button.action {
    width: 30px;
    position: relative;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 10px;
    border: none;
    transition: background-color 0.3s ease;

    svg {
      color: black;
      width: 20px;
      height: 20px;
      transition: color 0.3s ease;
    }

    &:hover {
      background-color: #eee;

      svg {
        color: #3091f6;
      }

      .cflow-tooltip-wrapper {
        display: block;
        white-space: normal;
        z-index: 10;
      }
    }
  }
}

.cflow-blank-plastic-movement-page-table {
  margin-top: 10px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 600px;
  padding: 0 !important;
  height: auto;

  .non-search {
    width: 100%;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      color: rgb(206, 77, 77);
      font-weight: 600;
    }
  }
}

.cflow-blank-plastic-movement-page-blank-tables {
  margin-top: 10px;
  padding: 0 !important;
  height: auto;
  background-color: #fff;
  display: flex;
  align-items: center;

  .cflow-movement-selected-order {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;

    .cflow-locked-table {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgb(175, 167, 167);
      top: 0;
      opacity: 0.7;

      svg {
        width: 50px !important;
        height: 50px !important;
        color: #000;
        opacity: 1;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        z-index: 100;
      }
    }

    .details-block {
      transition: max-height 0.7s linear;
      overflow: hidden;
    }

    .closed {
      max-height: 0px;
    }

    .opened {
      max-height: 500px;
    }

    &_header {
      width: 100%;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-weight: bold;
        font-size: 20px;
      }

      button {
        width: auto;
        padding: 7px 15px;
        background-color: #06503c;
        border: none;
        color: #fff;
        transition: background-color 0.2s ease;
      }

      button:hover {
        background-color: #043b2d;
      }
    }
  }
}

.cflow-blank-plastic-movement-page-bottom {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;

  button {
    max-width: 180px;
  }
}

.cflow-blank-plastic-page-wrapper {
  width: 100%;

  .movement-table-non-selected-plastic {
    width: 100%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    p {
      font-size: 18px;
      font-weight: 600;
      color: rgb(68, 65, 65);
    }
  }

  .cflow-plastic-handlers {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 30px;

    button {
      max-width: 150px;
      margin-right: 20px;
    }

    div.button-with-top-label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .top-label {
        margin-bottom: 3px;
        color: rgb(189, 126, 10);
        font-weight: 600;
      }

      .top-label-item {
        margin-bottom: 3px;
        font-size: 14px;
        color: rgb(189, 126, 10);
        font-weight: 500;
      }
    }
  }

  .cflow-number-orders {
    position: relative;

    input {
      padding: 0.6rem 0.6rem 0.6rem 0.6rem !important;
      text-transform: none;
      background-color: transparent;
      color: $dark-font;
      border: 1px solid $dark-font;
      border-radius: 0.25rem;
      font-size: 15px;
    }
  }

  .movement-blank-head-table {
    width: 100%;
    margin-top: 10px;
    border-bottom: 1px solid #06503c;
    max-width: 1700px;

    * {
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
    }

    thead {
      width: 100%;

      tr {
        width: 100%;
        background-color: #06503c;
        color: white;

        th {
          padding: 10px 15px;
        }
      }
    }

    tbody {
      width: 100%;

      tr {
        width: 100%;

        th {
          padding: 10px 15px;

          .cflow-dropdown-wrapper {
            margin-bottom: 0px !important;
            height: 41.2px !important;

            button {
              height: 41.2px !important;
            }
          }

          .cflow-number-orders {
            width: 100%;

            .cflow-form-wrapper-input {
              width: 100%;

              input {
                width: 100%;
                min-width: 0px;
              }
            }
          }

          .cflow-date-picker-dates-wrapper {
            width: 100%;

            .cflow-date-picker-start-date {
              width: 100%;
              margin: 0 !important;

              .cflow-form-wrapper-input {
                width: 100%;

                input {
                  width: 100%;
                }
              }
            }
          }

          .cflow-dropdown-wrapper {
            width: 100% !important;

            .cflow-dropdown-toggle-btn {
              width: 100% !important;
              display: flex;
              align-items: center;
              justify-content: center;

              .cflow-label {
                display: none;
              }

              .black-button {
                width: 100% !important;
                margin: 0 !important;

                .cflow-dropdown-caption {
                  width: calc(100% - 14px);
                }
              }
            }
          }
        }
      }
    }
  }

  .movement-blank-content-table {
    margin-top: 10px;
    width: 100%;
    max-width: 1800px;
    overflow-x: auto;
  }
}

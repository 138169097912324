.cflow-modal-window-wrapper {
  position: fixed;
  background-color: rgba(23, 45, 76, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-out;

  .cflow-modal-window {
    position: relative;
    min-width: 400px;
    width: 50%;
    max-width: 600px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition: opacity 0.3s ease-out;

    &.payment-system-dict-page {
      overflow: initial;
    }

    &.balances-types-dict-page {
      overflow: initial;
    }

    &.plastic-types-dict-page {
      overflow: initial;
    }

    &.plastic-acc-dict-page {
      min-height: 825px;
      overflow: initial;

      .cflow-modal-window-content {
        margin-bottom: 1rem;
      }

      .cflow-modal-window-footer {
        margin-top: 0;
      }
    }

    &.oper-day-import-modal {
      max-height: 700px;
    }

    &.logistic-page-setup-batch {
      min-height: 650px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .logistic-page-setup-batch,
  .logistic-batch-page-create-batch-plastic-list,
  .logistic-batch-page-zinnosti-batch,
  .logistic-batch-page-form-batches-group,
  .role-page-avaliable-role-operations,
  .admin-users-page-select-role,
  .package-receive-page-zinnosti-package {
    max-width: 1200px;
    width: 80%;
  }
  .print-movement-page,
  .print-adjust-page,
  .global-versions {
    max-width: 800px;
    min-width: 700px;
    width: 80%;
  }

  .users-page-add-new-user {
    max-height: 745px; //680px;
  }
  .users-page-ad-info {
    max-height: 700px;
    max-width: 900px;
    width: 900px;

    .cflow-modal-window-content {
      margin-bottom: 0.5rem;
    }
  }

  .branch-tree-modal {
    max-width: 1200px;
    width: 80%;
    max-height: calc(100% - 30px);
    overflow: auto;
  }

  .logistic-page-accurate-operation,
  .logistic-batch-page-form-package-batches {
    max-width: 1200px;
    width: 80%;
    max-height: 800px;
  }

  .logistic-page-plastic-multi-operations-form {
    max-width: 95vw;
    min-width: 1200px; //80%;
    max-height: 760px;
    height: 760px;
    padding-bottom: 0;
    padding-top: 0.5rem;
  }

  .blank-delivery-change-delivery-status {
    .cflow-modal-window-content {
      height: 130px;
      position: relative;
    }
  }

  .create-adjust-blank-select-tarif {
    max-width: 1200px;
  }

  .create-adjust-blank-select-tarif-more-info {
    max-width: 1000px;
  }

  .add-new-order-movement-page {
    height: 60%;
    min-height: 500px;
  }

  .balance-oper-day-prioritets {
    max-width: 1200px;
    width: 100%;

    .cflow-modal-window-content {
      position: relative;
    }
  }

  .cflow-modal-window-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .cflow-modal-window-header-question {
    text-align: center;
  }

  .cflow-modal-window-content {
    margin-bottom: 30px;
    height: 100%;

    p {
      line-height: 20px;
    }

    .waiter-form-wrapper-button {
      display: none;
    }
  }

  .cflow-modal-window-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    .cflow-button:not(:first-child) {
      margin-left: 20px;
    }
  }

  .modal-footer-without-buttons {
    width: 100%;
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 20px;

    button {
      width: auto;
      margin: 0 10px;
    }

    &.space-between {
      padding: 2rem 0 0;

      button {
        margin: 0;
      }
    }

    div {
      margin-left: auto;
      & > *:first-child {
        margin-right: 1rem;
      }
    }
  }

  &.empty {
    opacity: 0;
    z-index: -1;
    display: none;

    .cflow-modal-window {
      opacity: 0;
    }
  }

  .cflow-modal-window-btn-close {
    border: none;
    background: transparent;
    padding-right: 0;
  }

  .get-report-5-7-params-form {
    min-width: 800px;
  }

  .cflow-modal-window-content-subtitle {
    text-align: center;
  }
}

$white: #ffffff;
$black: #000000;

$main-bg: #dee0e0;

$dark-font: #222b45;

$caption: #8f9bb3;
$dropdown-placeholder: #9fabbf;
$input-bg: #f7f9fc;
$input-bg-hover: #dee0e0;
$input-border-invalid: #ff3d71;

$border: #e4e9f2;

$yellow: #e2e42b;
$bright-green: #027431;
$green: #004b46;
$green-hover: #043b2d;

//dashbord
$green-logo: #06503c;

$drop-down-li-bg: #f6f6f6;

//modals
$negative-200: #f59cb3;

$white: #ffffff;
$black: #000000;

$light-100: #f9fafb;
$light-200: #f3f5f7;
$light-300: #eaeef1;
$light-400: #e4e9ed;
$light-500: #e1e6eb;
$light-600: #c2cdd7;
$light-700: #95a6b8;
$light-800: #678099;
$light-900: #52677b;

$dark-100: #224574;
$dark-300: #172d4c;
$dark-400: #112239;
$dark-500: #0b1625;

$organic-brown: #e3f9a6;
$pastel-yellow: #faf884;
$golden-blonde: #fbe7a1;
$golden-silk: #f3e3c3;

$primary-100: #f3f9ff;
$primary-200: #c3dffc;
$primary-300: #83bdfa;
$primary-500: #3091f6;

$secondary-200: #ced3fd;
$secondary-300: #a8abd7;
$secondary-500: #0d148c;
$secondary-600: #021074;

$negative-100: #fdeff3;
$negative-200: #f59cb3;
$negative-500: #e1174c;

$neutral-100: #fffcf8;
$neutral-200: #ffe6c5;
$neutral-500: #ffbb5f;
$neutral-700: #ff9403;

$positive-100: #defcfc;
$positive-200: #b0f7f7;
$positive-500: #13c7c8;

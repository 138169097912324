.dashboard-blank-balance-oper-day-page {
  .log-table-wrapper {
    table.data-table {
      display: table;
      width: 100%;
    }
  }
}

.cflow-plastic-defect-modal {
  position: relative !important;
}

.cflow-blank-plastic-big-section {
  height: 100%;
  background-color: #fff;
  border-radius: 0.25rem;
  margin: 5px 0;

  h3.delivery-table-head {
    padding: 10px 0;
    text-align: left;
  }
}

.cflow-blank-balance-report-page-big-section {
  position: relative;
  height: 100%;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 0.25rem;
  margin: 10px 0;

  .oper-day-screen-header {
    display: flex;
    align-items: flex-start;

    .current-oper-day-info {
      min-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        margin-bottom: 5px;
      }

      p.date {
        margin-bottom: 5px;

        text-align: center;

        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
  }

  .header_search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    &_left {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      row-gap: 10px;

      .item {
        margin-right: 20px;
        display: flex;
        align-items: flex-start;

        label {
          margin-top: 0;

          span {
            font-size: 16px;
          }
        }
      }

      & > *:first-child {
        margin-top: 1px;
      }

      .datepicker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span.datepicker-label {
          font-size: 16px;
          margin-bottom: 0.5rem;
        }
      }

      > button {
        margin-top: 5px;
        width: 150px;
        align-self: center;
      }
    }
  }

  div.table {
    width: 100%;
    overflow: visible;

    .table-content {
      width: 100%;
    }
  }

  h3 {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }

  .buttons {
    display: flex;
    margin-top: 5px;

    button {
      max-width: 250px;
      margin-right: 20px;
    }
  }
}

.cflow-blank-balance-report-page-big-section.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
}

.cflow-blank-balance-report-page-small-section {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .item {
    width: calc(50% - 2px);
    overflow-y: auto;
    border: 1px solid rgb(194, 190, 190);
    height: 115px;
    background-color: #fff;

    h3 {
      font-family: 'Open Sans', sans-serif;
      text-align: center;
    }

    .date {
      margin-bottom: 5px;
      text-align: center;

      span {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .buttons {
      margin-top: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: 5px;
      column-gap: 5px;

      button {
        width: auto;
      }
    }
  }
}

.logistic-page-accurate-operation-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .string-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    label {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;
    }

    input {
      border-radius: 3px;
      padding: 8px 10px;
      font-size: 16px;
      border: 1px solid rgb(80, 73, 73);
    }
  }

  .bool-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    label {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;
      margin: 8px 0;
    }

    label {
      justify-content: flex-start !important;
    }
  }

  .branch-input {
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;
      margin: 8px 0;
    }

    .cflow-logistic-page-branch {
      width: 100%;
    }
  }

  .comment {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    border-top: 1.5px solid #aaa;

    label {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      margin-bottom: 5px;
    }
    label.invalid {
      color: red;
    }

    input {
      border-radius: 3px;
      padding: 8px 10px;
      font-size: 14px;
      border: 1px solid rgb(80, 73, 73);
    }

    input.invalid {
      border: 1px solid red;
    }

    .input-wrapper {
      margin-top: 1rem;
    }
  }
}

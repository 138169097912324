.cflow-dictionary-page {
  height: calc(100vh - 76px - 2rem);
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cflow-table-padding-top {
  height: 1rem;
  width: 100%;
  background-color: $white;
}

.cflow-table-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $main-bg;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: $dark-font;
  font-size: 0.9375rem;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullwidth-container {
  width: 100%;

  .cflow-flex-row {
    display: flex;
    justify-content: space-between;

    & > * {
      flex-basis: 50%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  .hor-labelled-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;

    label {
      margin-left: 0.5rem;
      padding-top: 0.25rem;
      cursor: pointer;
    }

    input {
      width: auto;
    }
  }
}

.cflow-page-content.cflow-logistic-page.dashboard-dict-balance-type-page {
  td.perso_file {
    div {
      text-align: center;

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.cflow-page-content.cflow-logistic-page.dashboard-dict-plastic-type-page {
  td.send_to_cm {
    div {
      text-align: center;

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

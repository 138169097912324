.cflow-icon{
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-right: .5rem;
}

.cflow-big-icon{
  svg{
    width: 2rem;
    height: 2rem;
  }
}


.cflow-middle-icon{
  svg{
    width: 1.25rem;
    height: 1.25rem;
  }
}

.cflow-small-icon{
  svg{
    width: 1rem;
    height: 1rem;
  }
}
.cflow-supper-small-icon{
  svg{
    width: .875rem;
    height: .875rem;
  }
}


.cflow-blank-plastic-page-wrapper {
  .no-selected-plastic {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: #f1eee9;

    span {
      color: #9c8059;
    }
  }

  .table {
    width: 100%;
    overflow: auto;
    max-height: 500px;
    // border: 4px solid #bebebe;
    border-radius: 3px;

    .cflow-date-picker-start-date {
      margin: 0 !important;
    }
  }
}

@import '../../../../../style/colors.scss';

.input-wrapper {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.85rem;

  .select-dropdown-indicator {
    width: .75rem;
    height: .85rem;

    svg path {
      fill: $green;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menu {
  animation: fadeIn 0.2s ease-in-out;
}
@import '../../../../style/colors.scss';

.cflow-blank-search-area {
  .cflow-blank-search-area-container.closed {
    max-height: 0px;
    overflow: hidden !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .cflow-blank-search-area-container {
    display: flex;
    max-height: 1000px;

    @media screen and (max-width: 1024px) {
      //1030px
      max-height: 1500px;
    }

    @media screen and (max-width: 900px) {
      max-height: 1700px;
    }

    transition: max-height 0.8s ease-out, padding 0.5s ease-out;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #eee;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;

    .content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .double-date-picker {
      display: flex;
      flex-direction: column;

      .datepickers-row {
        display: flex;
        flex-wrap: wrap;
      }

      span.double-datepicker-label {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
    }

    .datepicker-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 225px;

      span.datepicker-label {
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
    }

    .dates-picker {
      width: auto;
      min-width: 150px;
      width: auto;
      max-width: 430px !important;

      label {
        margin-bottom: 0.5rem;
        display: block;
      }
    }

    .cflow-date-picker-dates-wrapper {
      display: flex;

      .cflow-date-picker-start-date {
        margin-right: 1rem;
      }

      .DayPicker {
        position: absolute;
        top: 100%;
        z-index: 5;

        .CalendarMonth_caption,
        .DayPicker_weekHeader_li,
        .CalendarDay {
          font-family: 'Futura-PT-Light', sans-serif;
        }
      }
    }

    .cflow-clear-filter {
      position: absolute;
      top: 14%;
      right: 5px;
      padding: 0.25rem;
      margin: 0;
      cursor: pointer;
    }

    .cflow-date-picker-start-date,
    .cflow-date-picker-end-date,
    .cflow-form-wrapper-input {
      position: relative;

      input {
        padding: 0.6rem;
        text-transform: none;
        background-color: transparent;
        color: $dark-font;
        border: 1px solid $dark-font;
        border-radius: 0.25rem;
        font-size: 15px;
      }
    }

    .cflow-filter-item-wrapper {
      min-width: 150px;
      width: auto;
      max-width: 250px;

      label {
        margin-top: 0;

        span {
          font-size: 16px;
        }
      }

      .cflow-filter-item-label {
        margin-bottom: 0.5rem;
        display: block;

        span.selected {
          font-weight: bold;
        }
      }

      &.dates-picker {
        min-width: 150px;
        width: auto;
        max-width: 390px;
      }

      &.number-orders {
        min-width: 150px;
        width: auto;
        max-width: 190px;
      }

      &.ignore-zero-rest-items {
        width: 100%;
        display: block;
        margin: 0;

        .cflow-filter-item-label {
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }

        .cflow-ignore-zero-rest-items {
          margin-left: 0.5rem;
          margin-top: 0.25rem;
        }

        .cflow-form-wrapper-input {
          display: flex;
          align-items: center;

          label {
            margin: -0.25rem 0 0 0.25rem;
          }
        }
      }

      &.control-buttons {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        max-width: 100%;
        margin-right: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  // for oldest movement page selectors
  .search-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 10px 0;

    button {
      margin-right: 20px;
      width: auto;
    }
  }
}

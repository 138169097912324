@import '../../../../../../style/colors.scss';

.dashboard-reports-page {
  .cflow-modal-window {
    width: 700px !important;
  }

  .report-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 23rem;
    max-height: 90vh;
    height: auto;

    .rangedatepicker-wrapper {
      flex-wrap: nowrap;
    }

    .fields {
      max-width: 100% !important;
    }

    .departments,
    .perso-branch,
    .package-cards,
    .delivery-plastic,
    .balance-signer {
      display: flex;
      margin-bottom: 1rem;

      .any-branch-wrapper {
        flex-basis: 50%;
        padding-right: 0.5rem;

        &:only-child {
          flex-basis: 100%;
        }

        .any-branch {
          display: flex;
          align-items: center;
          border: 1px solid $border;
          border-radius: 5px;
          margin-top: 0.25rem;
          padding: 0.25rem;

          &.invalid {
            border-color: $input-border-invalid;
          }

          *:first-child {
            margin-right: 1rem;
            font-weight: bold;
          }

          *:last-child {
            width: auto;
            margin-left: auto;
            padding: 0.5rem 4px;
          }
        }
      }

      & > .cflow-dropdown-wrapper,
      & > .cflow-multiselect-wrapper {
        flex-basis: 50%;

        &:nth-child(1):not(:only-child) {
          padding-right: 0.5rem;
          max-width: 50%;
        }

        &:nth-child(1):only-child {
          flex-basis: 100%;
        }

        &:nth-child(2) {
          padding-left: 0.5rem;
          max-width: 50%;
        }
      }

      & > .cflow-multiselect-wrapper {
        .cflow-label {
          font-weight: normal;
          font-size: 0.95rem;
          color: $dark-font;
        }
        .cflow-multiselect-toggle-btn button {
          height: 3rem;
          border-color: $border !important;
          font-size: 0.7rem;
          font-family: 'Open Sans', sans-serif;

          &:focus {
            border-color: $dark-font !important;
            background-color: $input-bg-hover;

            svg {
              path {
                fill: $dark-font;
              }
            }
          }
        }

        &.invalid {
          .cflow-multiselect-toggle-btn button {
            border-color: $input-border-invalid !important;
          }
        }

        button {
          text-align: left;

          p {
            color: $dark-font !important;
            font-size: 15px;
          }
        }
      }

      .cflow-label-fill {
        width: 100% !important;
      }
    }

    & > *:first-child {
      margin-bottom: 2rem;
    }

    & > *:last-child {
      margin-top: auto;
    }

    .modal-actions {
      display: flex;
      justify-content: center;

      .cflow-button-modal-button {
        width: auto;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    .cflow-dropdown-toggle-btn {
      button {
        border-radius: 3px;

        &.invalid {
          border-color: $input-border-invalid;
        }
      }
    }

    .not-null-remains {
      label {
        justify-content: flex-start !important;
      }
    }
  }

  .cflow-dropdown-wrapper {
    .cflow-label {
      margin-bottom: 0.5rem;
      width: 15rem;
      font-size: 0.95rem;
      font-weight: normal;
      color: $dark-font;
    }

    ul {
      position: fixed;
      min-width: auto;
      max-width: 700px;
    }
  }

  .perso-branch,
  .package-cards {
    & > .cflow-dropdown-wrapper {
      &:only-child {
        ul {
          min-width: 560px;
        }
      }

      &:not(:only-child) {
        ul {
          min-width: 325px;
        }
      }
    }
  }
}

// .dashboard-reports-1-1-page,
// .dashboard-reports-1-2-page,
// .dashboard-reports-1-3-page,
// .dashboard-reports-2-1-page {

//   .report-form {
//     height: 22rem;

//     .report-signer {
//       margin-top: 11.25rem;
//     }
//   }
// }

//CSS-classes for reports with persoType and branch params
// .dashboard-reports-1-1-page,
// .dashboard-reports-1-2-page,
// .dashboard-reports-1-3-page,
// .dashboard-reports-2-1-page,
// .dashboard-reports-2-2-page,
// .dashboard-reports-2-3-page,
// .dashboard-reports-2-4-page,
// .dashboard-reports-2-5-page,
// .dashboard-reports-2-6-page,
.dashboard-reports-3-2-1-page,
.dashboard-reports-3-2-2-page,
.dashboard-reports-3-2-3-page,
.dashboard-reports-3-5-1-page,
.dashboard-reports-3-5-2-page,
.dashboard-reports-4-1-page,
.dashboard-reports-4-5-2-page {
  .report-form {
    // height: 31rem;

    .report-signer {
      margin-top: 16.75rem;
    }
  }
}

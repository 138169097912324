@import '../../../style/colors.scss';

.cflow-input-wrapper-block {
  display: flex;
  align-items: center;
  width: 100%;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: $caption;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
    margin-right: 10px;
    min-width: 100px;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.3875rem 0.7rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    border: 1px solid $border;
    border-radius: 0.25rem;
    background-color: $input-bg;
    color: $dark-font !important;
    transition: 0.35s all ease-out;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 400;
      color: $dropdown-placeholder;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 400;
      color: $dropdown-placeholder;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 400;
      color: $dropdown-placeholder;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-weight: 400;
      color: $dropdown-placeholder;
    }

    &:hover {
      background-color: $input-bg-hover;
      border-color: $input-bg-hover;
    }

    &:focus {
      background-color: $white;
      border-color: $green;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $input-bg-hover;
      border-color: $input-bg-hover;
    }
  }

  &.invalid {
    label {
      color: $input-border-invalid !important;
    }

    input,
    textarea,
    button {
      border-color: $input-border-invalid;
    }
  }

  input {
    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    resize: vertical;
  }
}

.span-copy-label-from-cflow-input-wrapper-block {
  display: block;
  margin-bottom: 0.5rem;
  color: $caption;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  margin-right: 10px;
  min-width: 100px;
}
@import '../../../../style/colors.scss';

.dashboard-blank-balance-report {
  .data-row:nth-of-type(odd) > td {
    background-color: $white
  }
    
  .data-row:nth-of-type(even) > td {
    background-color: $primary-100
  }

  .data-row {
    td {
      padding: 0.25rem 0.5rem;
      transition: all .35s ease-out;
    }

    &:hover td {
        background-color: $light-200;
        cursor: default;
    }
  }

  .data-row-total {
    font-weight: bold;
  }

  .data-row.data-row-selected td {
    background-color: $main-bg;

    .data-row-menu-cell-wrapper {
      height: 100%;
      display: flex;
      align-items: center;

      .data-row-menu-cell-data {
        flex-basis: 100%;
        height: 100%;
      }
      
      .data-row-menu {
        position: relative;
        height: 100%;
        width: 2rem;
        height: auto;
        cursor: pointer;
        transform: translateX(50%);

        .data-row-menu-items {
          position: absolute;
          right: 100%;
          top: 50%;
          border: 1px solid $border;
          border-radius: 3px;
          background-color: $white;
          font-size: .9rem;

          li {
            padding: 0.25rem 0.5rem;
            white-space: nowrap;
            transition: all .35s ease-out;

            &:hover {
              background-color: $light-200;
            }

            &:not(:last-child) {
              border-bottom: 1px solid $border;
            }
          }
        }
      }
    }
  }
}
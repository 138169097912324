.blank-logistic-batch-page-form-package-batches {
  width: 100%;
  .table {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    border: 1px solid #aaa;
    border-radius: 5px;
    table {
      width: 100% !important;
    }
  }
}

.logistic-batch-page-plastic-list-modal {
  width: 100%;
  display: flex;
  flex-direction: column;

  .logistic-table {
    height: 400px !important;
  }

  .modal-footer-without-buttons {
    margin-top: 20px;
  }
}
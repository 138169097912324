.cflow-table-wrapper {
  width: 100%;
  background-color: $white;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: auto;
  overflow: auto;

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    border-collapse: separate;

    thead {
      tr:nth-child(1) {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      tr:nth-child(1) th {
        height: 48px;
        background: $green-logo;
        padding: 0.25rem 1rem;
        border: 1px solid $input-bg-hover;
        border-top: none;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .cflow-table-sortby-direction {
            display: none;

            &.desc,
            &.asc {
              display: block;

              svg path {
                fill: #dee0e0; //$dark-300;
              }
            }

            &.asc {
              transform: rotate(180deg);
            }
          }

          :hover {
            cursor: pointer;
          }
        }

        span strong {
          color: $white;
        }
      }

      tr:nth-child(2) th {
        position: sticky;
        top: 48px;
        z-index: 1;
        border: 1px solid $input-bg-hover;
      }
    }

    tbody {
      tr {
        height: 40px;
        border-bottom: 1px solid $light-500;
        transition: background-color 0.3s ease-out;

        &:nth-child(3n + 2) {
          background-color: $white;
        }

        &:nth-child(2n + 2) {
          background-color: #f7f9fc;
        }

        &:hover {
          background-color: $light-300;

          & td {
            border: 1px solid $light-600;
            border-top-width: 1.5px;
          }
        }
      }

      td {
        padding-left: 10px;
        padding-right: 10px;
        border: 1px solid $border; //c0c1c2;

        p {
          align-items: center;
          word-break: keep-all;
          font-size: 0.75rem;
        }

        &:last-child {
          padding-right: 1rem;
        }

        &.plastic_type_name {
          min-width: 250px;

          > div {
            display: flex;
            justify-content: space-between;
            align-content: flex-start;
          }
        }

        &.codes,
        &.avail_operations {
          padding: 0;
          min-width: 150px;
        }

        input[type='checkbox']:disabled {
          cursor: default;
        }
      }
    }
  }
}

.cflow-table-wrapper {
  table {
    tr {
      th.plastic_type_id,
      td.plastic_type_id {
        display: none;
      }

      .cflow-additional-user-actions-wrapper {
        display: none;
      }

      svg {
        path {
          fill: transparent;
        }
      }

      &.selected {
        background-color: $positive-200 !important;

        & td {
          border: 1px solid $positive-500 !important;
          border-top-width: 1.5px !important;
        }

        .cflow-additional-users-actions {
          position: absolute;
          top: -0.75rem;
          right: 1rem;
          cursor: pointer;
        }

        .cflow-additional-user-actions-wrapper {
          display: block;
        }

        .cflow-table-td-complex-items {
          position: relative;

          li {
            .cflow-additional-users-actions {
              display: none;
            }

            &:hover {
              .cflow-additional-users-actions {
                cursor: pointer;
                display: block;
                position: absolute;
                left: auto;
                top: -0.25rem;
                right: 2rem;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    td {
      > div {
        position: relative;
        min-height: 1rem;
      }

      &.signer_name {
        min-width: 150px;
      }

      &.suspended {
        text-align: center;
        padding-left: 0;
        padding-right: 0;

        .cflow-suspended-mark {
          margin-top: 5px;
          cursor: default;
          pointer-events: none;
        }
      }

      .cflow-actions {
        position: absolute;
        top: 0;
        height: 100%;
        left: 102%;
        width: 60px;
        display: none;
      }

      .cflow-table-td-complex-items {
        li {
          position: relative;
          border-bottom: 1px solid $border;

          p {
            padding: 0.5rem 0.5rem;
          }

          .cflow-additional-users-actions {
            p {
              padding: 0;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  &.dict-plastic-type {
    .cflow-additional-user-actions-toggle-btn {
      button {
        top: 0;
      }
    }
  }

  &.dict-plastic-acc {
    .cflow-additional-user-actions-toggle-btn {
      display: none;
    }
  }

  .cflow-quick-filter {
    padding: 0.25rem 1rem;
    width: 100%;
    border-color: white;
    border-style: none;
  }
}

.admin-users-page-create-new {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .user-content-item {
      margin-bottom: 10px;
      width: 100%;

      .cflow-input-wrapper-block {
        label {
          width: 200px;
        }
      }
    }

    .checkboxes {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      label {
        margin-right: 30px;
      }
    }

    .custom-content-input-item {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      >span {
        color: rgb(63, 58, 58);
        width: 50%;
        display: flex;
        align-items: center;

        svg {
          font-size: 14px;
          margin-right: 10px;
        }
      }

      input {
        padding: 7px 10px;
        width: calc(50% - 20px);
        border: none;
        border: 1px solid rgb(95, 95, 95);
        border-radius: 5px;
        font-size: 14px;
      }

      button.branches {
        padding: 5px 10px;
        width: calc(50% - 20px);
        background-color: rgb(223, 223, 223);
        border: 1px solid rgb(95, 95, 95);
        border-radius: 5px;
        position: relative;

      }

      button.invalid {
        border-color: #ff3d71;
        color: #ff3d71;
      }

      .close-icon {
        position: absolute;
        right: -10px;
        font-size: 15px;
        top: calc(50% - 5px);
        cursor: pointer;
      }

      .cflow-dropdown-wrapper {
        width: calc(50% - 20px) !important;
      }

      button.branches:hover {
        background-color: #aaa;
        color: #000;
      }
    }
  }
}
@import '../../../../style/colors.scss';

.input-wrapper {
  .error-container {
    position: relative;
  }

  &:not(.input-wrapper-select) {
    input {
      height: 38px;
    }
  }

  input:not([type='checkbox']) {
    min-width: 50px;
  }

  input {
    padding-left: 0.5rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    border: 1px solid $green;
    border-radius: 3px;
    font-family: inherit;
    font-size: 0.85rem;
    font-weight: normal;
    line-height: unset;
    background-color: $white;
    transition: all 0.2s ease-in-out;

    &[type='checkbox'],
    &[type='radio'] {
      // width: 1.25rem;
      width: 20px;
      height: 20px;
    }

    &:focus {
      border: 1px solid $green;
      outline: 1px solid $green;
    }

    &:hover {
      border: 1px solid $dropdown-placeholder;

      &:focus {
        border: 1px solid $green;
      }
    }

    &::placeholder {
      text-align: left;
    }

    &:disabled {
      border: 1px solid $dropdown-placeholder;
    }
  }

  &.invalid {
    input {
      border: 1px solid $input-border-invalid !important;

      &:focus {
        border: 1px solid $input-border-invalid !important;
        outline: 1px solid $input-border-invalid !important;
      }

      &:hover {
        border: 1px solid $input-border-invalid !important;
      }
    }
  }

  &.label-left,
  &.label-right {
    label {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      span.input-item-label {
        white-space: nowrap;
        margin-bottom: 0;
      }
    }
  }

  &.label-right {
    label {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  &.input-wrapper-checkbox.label-right,
  &.input-wrapper-checkbox.label-left {
    align-items: center;
  }

  label {
    margin-bottom: 0;
    font-size: 0.85rem;
    font-weight: normal;

    span {
      display: block;
      margin-bottom: 0.5rem;
    }
  }

  .input-item-error {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    font-size: xx-small;
    line-height: 1;
    color: $input-border-invalid;
  }

  button.pick-value-button {
    padding: 6px;
    width: auto;
  }
}

.dashboard-blank-logistic-batch-page {
  .logistic-batch-page-ask-for-signer {
    max-width: 800px !important;

    .ask-for-signer-form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      max-width: 100%;

      & > *:first-child {
        margin-bottom: 2rem;
        max-width: 100%;

        button {
          p {
            max-width: 95% !important;
            width: 95% !important;
          }
        }

        ul {
          position: fixed;
          min-width: auto;
          max-width: 700px;
        }
      }

      .modal-actions {
        display: flex;
        justify-content: center;

        & > *:first-child {
          margin-right: 1rem;
        }

        button {
          width: auto;
        }
      }
    }
  }
}
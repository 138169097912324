@import '../../../../../style/colors.scss';

.input-wrapper-multiselect {
  &.disabled {
    .cflow-multiselect-wrapper
      .cflow-multiselect-toggle-btn
      button.black-button {
      border: 1px solid $dropdown-placeholder !important;
    }
  }

  &.invalid {
    .cflow-multiselect-wrapper
      .cflow-multiselect-toggle-btn
      button.black-button {
      border: 1px solid $input-border-invalid !important;
    }
  }

  .cflow-multiselect-wrapper {
    .cflow-multiselect-toggle-btn {
      button.black-button {
        padding: 0.35rem 0;
      }

      p.placeholder {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: normal !important;
        text-transform: lowercase;
      }

      .cflow-multiselect-caption {
        p {
          font-weight: normal;
          text-align: left;
        }
      }

      .cflow-icon {
        margin-right: 6px;

        svg {
          path {
            transform: scale(0.9);
            fill: $dark-font;
          }
        }
      }
    }
  }
}

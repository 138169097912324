.dashboard-branches-page {
  .sync-subbranches-modal {
    .sync-subbranches-question {
      padding: 1rem 0;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }

    .modal-actions {
      display: flex;
      justify-content: center;

      button {
        width: auto;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
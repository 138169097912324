.cflow-logistic-page-select-user {
  width: 100%;

  .select-user {
    width: calc(100% - 30px);
  }

  .modal-actions {
    display: flex;
    width: 100%;
    margin-top: 30px;
    align-items: center;
    justify-content: flex-end;
    button {
      width: auto;
      margin-left: 10px;
    }
  }
}

.report-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 1rem 1rem 4rem;
  background-color: transparent;

  .report-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cflow-anchor-button {
      padding-right: 0;
    }
  }
}

@media (max-width: 780px) {
  .report-toolbar {
    .report-actions {
      display: block;

      li .cflow-anchor-button {
        padding: .5rem;
      }
    }
  }
}


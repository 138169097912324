@import '../../../../../../../style/colors.scss';

td {
  text-align: left;

  &.blank-operlog-message-type-cell {
    position: relative;
    min-width: 200px;

    div {
      position: absolute;
      top: 0;
      right: 0;
      width: 80%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      display: flex;
      transition: all .35s ease-out;
      text-align: right;
    }
    
    &:not(:last-child) div {
      position: absolute;
      top: 0;
      right: 0;
      width: 80%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      border-left: 1px solid $border;
      display: flex;
      transition: all .35s ease-out;
    }
    
    div span {
      margin: auto;
    }
  }

  &.blank-operlog-message-time-cell {
    text-align: center;
  }
  
  &.blank-operlog-message-type-cell,
  &.blank-operlog-message-text-cell,
  &.blank-operlog-message-time-cell {
    border: 1px solid $white;
  }

  &.blank-operlog-message-text-cell, 
  &.blank-operlog-message-time-cell {
    padding: 0.25rem 0.5rem ;
  }
}

.blank-operlog-msg-error td, .blank-operlog-msg-error span {
  color: red;
}

.operlog-msg-row {
  height: 3.25rem;
}


.operlog-msg-row:nth-of-type(odd) {
  .blank-operlog-message-type-cell div,
  .blank-operlog-message-text-cell,
  .blank-operlog-message-time-cell,
  .blank-operlog-message-menu-cell {
    background-color: $light-300;
  }
}
  
.operlog-msg-row:nth-of-type(even) {
  .blank-operlog-message-type-cell div,
  .blank-operlog-message-text-cell,
  .blank-operlog-message-time-cell,
  .blank-operlog-message-menu-cell {
    background-color: $light-200;
  }
}

.operlog-msg-row:hover {
  .blank-operlog-message-type-cell div,
  .blank-operlog-message-text-cell,
  .blank-operlog-message-time-cell,
  .blank-operlog-message-menu-cell {
    background-color: $light-600;
  }
}
.dashboard-reports-page { 
  
  .report-signature {
    display: flex;
    padding-bottom: 1rem;
    font-size: .75rem;
  }

  .signer-signature {
    margin-left: auto;
    padding-left: 1rem;
  }

  .signer-position {
    padding-right: 1rem;
    font-size: inherit;
  }

  .signer-name {

    &:first-child {
      min-width: 10rem;
    }

    padding-left: 1rem;
    font-size: inherit;
    white-space: nowrap;
  }
}
@import '../../../../../style/mixins/table_config.scss';

.cflow-blank-plastic-big-section {
  .create-delivery-title {
    margin-bottom: 10px;
  }

  .create-delivery-blank-global-table {
    width: 100%;
    min-height: 170px;
    overflow-x: auto;

    .cflow-date-picker-start-date {
      margin-bottom: 0 !important;
    }
  }

  .create-delivery-blank-actions {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
    width: calc(100% - 2rem);
    margin-top: 10px;

    button {
      width: auto;
      margin-left: 10px;

      &.no-wrap {
        white-space: nowrap;
      }

      &:first-of-type {
        margin-left: auto;
      }
    }

    span.warning {
      color: chocolate;
      font-weight: 600;
      margin-left: 20px;
    }
  }

  .records-section {
    width: calc(100% - 30px);
    margin: 20px 0;

    .section-title {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .table {
      width: 100%;
      overflow: auto;
      max-height: 300px;
    }

    .records-section-actions {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      button {
        width: auto;
      }
    }
  }

  .not-found {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(250, 239, 220);
    span {
      color: #ce4d4d;
      font-weight: 600;
    }
  }
}

.cflow-blank-plastic-big-section.create-delivery-blank {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  margin: 0;
}

.print-content {
  position: absolute;
  width: 100%;
  z-index: -1;
  padding: 2.5rem 3rem;
  font-family: 'Open-Sans', sans-serif;

  header {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .form-type {
      font-size: 8px;
      text-align: right;
    }

    h1 {
      font-size: 13px;
      text-align: center;
      line-height: 20px;

      .reg-caption {
        letter-spacing: 6px;
        text-transform: uppercase;
      }
    }

    .header-line {
      border-bottom: 1px solid #aaa;
      font-size: 13px;
      text-align: center;
      font-weight: bold;

      &.sending {
        position: relative;
        margin-bottom: 11px;

        &::after {
          content: "назва підприємства спецзв'язку";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          font-size: 8px;
          font-weight: normal;
          text-align: center;
        }
      }

      &.origin {
        position: relative;
        margin-bottom: 11px;

        &::after {
          content: 'назва підприємства, організації, номер угоди';
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          font-size: 8px;
          font-weight: normal;
          text-align: center;
        }
      }
    }

    .sender {
      font-weight: normal;
    }

    .reg-requisites {
      display: flex;
      padding-bottom: 0.4rem;
      font-size: 10px;

      & > *:first-child {
        margin-right: auto;
        color: #f00;
      }
    }

    span {
      font-size: bold;
    }
  }

  span {
    font-size: inherit;
  }

  .reg-items {
    width: 100%;
    border-collapse: collapse;
    font-size: 9px;

    td,
    th {
      padding: 0 3px;
    }

    thead {
      th {
        border: 1px solid #aaa;
        text-align: center;
        vertical-align: middle;
        font-weight: normal;

        &.row-num,
        &.send-class {
          min-width: 75px;
          width: 75px;
        }

        &.address,
        &.recipient {
          width: calc(100% - 156px) / 2;
        }

        .address-items {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      tr:last-child {
        background-color: #eee;
      }
    }

    tbody {
      td {
        border: 1px solid #aaa;
      }

      tr.item {
        td.item-marker {
          width: 30px;
          border: none;
          text-align: center;
        }
        td.item-ordinal {
        }
        td.item-address {
        }
        td.item-recipient {
        }
        td.item-send-class {
          width: 75px;
        }
      }
    }

    tfoot {
      font-size: 10px;
      font-weight: bold;

      tr {
        &:first-child {
          td {
            padding-top: 0.5rem;
          }
        }

        &.reg-clerk {
          // font-weight: normal;

          // td.reg-clerk-signiture {
          //   font-style: italic;
          //   font-size: smaller;
          // }

          td.reg-stamp {
            font-weight: bold;
          }
        }

        td.underlined {
          border-bottom: 1px solid #aaa;
        }
      }
    }
  }
}

@import '../../../../style/colors.scss';

.import-history {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;

  h2 {
    padding-bottom: 1rem;
  }

  & > *:last-child {
    flex-basis: 100%;
  }

  form {
    display: flex;
    justify-content: flex-start;

    button.cflow-button-contained {
      align-self: flex-end;
      width: auto;
      min-width: 7rem;
      max-height: 2.5rem;
      margin-bottom: 1rem;

      &:focus {
        outline: 5px solid $input-bg-hover;
      }
    }

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .import-data {
    th {
      padding: 0.5rem;
    }
  }
}

.cflow-dashboard-wrapper {
  width: 100vw;
  min-height: 100vh;
  background-color: $white;

  &.collapsed {
    .cflow-dashboard-content {
      padding-left: 3.5rem;
    }

    .cflow-menu {
      min-width: 3.5rem;
      width: 3.5rem;

      h6,
      p {
        display: none;
      }
    }
  }
}

.cflow-dashboard-content {
  position: relative;
  padding-left: 16rem;
  transition: padding-left 0.3s ease;

  .cflow-menu {
    position: absolute;
    left: 0;
    transition: width 0.3s ease, min-width 0.3s ease, max-width 0.3s ease;
  }

  .cflow-page-content-wrapper {
    position: relative;
    padding: 10px 1rem;
    background-color: $main-bg;
    height: calc(100vh - 76px);
    overflow-y: scroll;

    &.dashboard-blank-plastic-page {
      height: 100%;
    }
  }
}

.cflow-page-content.dashboard-blank-plastic-page,
.cflow-page-content.dashboard-blank-plastic-movement-page,
.cflow-page-content.dashboard-blank-plastic-adjust-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cflow-blank-search-area {
    height: auto !important;
  }
}

.cflow-page-content.dashboard-blank-delivery-page {
  height: 100%;
  .cflow-blank-search-area {
    height: 100%;
  }
}

.cflow-page-content.dashboard-blank-balance-oper-day-page,
.cflow-page-content.dashboard-blank-pin-balance-oper-day-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cflow-blank-balance-report-page-big-section {
    height: auto !important;
  }

  .cflow-blank-balance-report-page-small-section {
    height: auto !important;
  }
}

.cflow-page-content.dashboard-blank-logistic-batch-page,
.cflow-page-content.dashboard-blank-logistic-page,
.cflow-page-content.dashboard-blank-package-receive-page,
.cflow-page-content.dashboard-blank-delivery-page,
.cflow-page-content.dashboard-pin-logistic-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cflow-blank-search-area:not(.create-delivery-blank) {
    height: auto !important;
  }

  .cflow-blank-plastic-big-section:not(.create-delivery-blank) {
    height: auto !important;
  }
  .cflow-screen-warning-block {
    height: auto !important;
  }
}

// @media screen and (max-width: 1366px) {
//   .cflow-dashboard-content {
//     padding-left: 3.5rem;
//   }

//   .cflow-menu {
//     min-width: 3.5rem;
//     width: 3.5rem;

//     h6,
//     p {
//       display: none;
//     }
//   }
// }

.global-versions-modal {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .corner-close {
    position: absolute;
    top: -5px;
    right: 5px;
    padding: 5px;
    cursor: pointer;
  }

  .corner-back {
    position: absolute;
    top: -5px;
    left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
    border: 1px solid #333;
    padding: 5px;
    background-color: #eee;
    &:hover {
      background-color: #fff;
    }
    svg {
      font-size: 18px;
      color: #333;
    }
    span {
      color: #333;
    }
  }

  .modal-title {
    font-size: 24px;
  }

  .single-version-title {
    font-size: 24px;
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .current-mark {
    padding: 8px 15px;
    height: auto;
    background-color: forestgreen;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #fff;
      font-weight: 600;
    }
  }

  .list {
    width: calc(100% - 30px);
    max-width: 800px;
    border-radius: 5px;
    max-height: 400px;
    overflow-y: auto;
    -webkit-box-shadow: 1px 1px 3px 0px rgba(34, 60, 80, 0.72);
    -moz-box-shadow: 1px 1px 3px 0px rgba(34, 60, 80, 0.72);
    box-shadow: 1px 1px 3px 0px rgba(34, 60, 80, 0.72);

    .item {
      width: 100%;
      background-color: transparent;
      border-bottom: 2px solid #cfcfcf;
      padding: 10px 15px;
      transition: background-color 0.3s ease;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      &:hover {
        background-color: #eee;
        cursor: pointer;
      }

      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 20px;
      }

      .version-number {
        font-weight: 600;
        font-size: 18px;
      }

      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 5px;

          span.date {
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-size: 13px;
            svg {
              font-size: 14px;
            }
          }
          > svg {
            font-size: 20px;
          }
        }

        .more {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 5px;
        }
      }
    }

    .item.current {
      .version-number {
        color: forestgreen;
      }
    }

    .item:last-child {
      border-bottom: none;
    }
  }

  .single-version-body {
    width: 100%;
    display: flex;
    flex-direction: column;

    .content {
      margin-top: 10px;
      white-space: pre-wrap;
      line-height: 150%;
      tab-size: 3;
    }
  }
}

.logistic-batch-page-create-batch-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-item {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 20px;
    margin: 10px 0;

    label {
      font-size: 14px;
      letter-spacing: 0.2px;
      max-width: 45%;
    }

    input {
      padding: 7px 10px;
      width: 50%;
      border: none;
      border: 1px solid rgb(95, 95, 95);
      border-radius: 5px;
      font-size: 14px;
    }

    button.branches {
      min-width: 50%;
      padding: 5px 10px;
      width: calc(50% - 20px);
      background-color: rgb(223, 223, 223);
      border: 1px solid rgb(95, 95, 95);
      border-radius: 5px;
      position: relative;
    }

    .cflow-dropdown-wrapper {
      min-width: 50%;
      margin: 0 !important;
    }

    .close-icon {
      position: absolute;
      right: -10px;
      font-size: 15px;
      top: calc(50% - 5px);
      cursor: pointer;
    }
  }

  .cflow-filter-item-wrapper {
    width: 50%;
  }

  label {
    align-self: flex-start;
    margin-left: calc(20px + 2.5%);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .cflow-date-picker-dates-wrapper {
    width: 100%;

    div.cflow-date-picker-start-date {
      width: 100%;
    }

    input {
      width: 100% !important;
    }
  }

  .data-label {
    align-self: flex-start;
    margin-left: calc(20px + 2.5%);
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
  }
}
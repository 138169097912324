.balance-oper-day-prioritets-modal {
  width: 100%;

  .content {
    width: 100%;
    margin-bottom: 60px;

    .table {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      border: 1px solid #bfc3ca;
    }
  }
}
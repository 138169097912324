.cflow-plastic-defect-modal {
  margin-top: 20px;

  p.plastic-type {
    font-size: 18px;

    span {
      font-size: inherit;
      font-weight: bold;
      margin-left: 10px;
    }
  }

  div.tarif-div {
    margin: 10px 0;
  }

  div.defect-select-count {
    margin-top: 15px;
    div.cflow-input-wrapper-block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  div.select-count {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;

    p {
      font-size: 16px;
    }

    input {
      font-size: 16px;
      margin-top: 5px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1.5px solid #969292;
    }

    input:focus {
      border: 1.5px solid rgb(0, 0, 0);
    }
  }
}

// @import '../../../../style/colors.scss';

.data-table {
  .data-row.data-row-log-success {
    td {
      color: green;
    }
  }

  .data-row.data-row-log-error {
    td {
      color: red;
    }
  }
}
@import '../../../../style/colors.scss';

tr {
  color: $dark-font;

  &.invalid {
    color: $negative-500;
  }

  ul {
    color: $dark-font;
  }
}

td.row-menu-cell {
  min-width: 1.75rem;
}

td {
  text-align: left;

  &.message-type-cell {
    position: relative;

    div {
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      display: flex;
      transition: all 0.35s ease-out;
    }

    &:not(:last-child) div {
      position: absolute;
      top: 0;
      right: 0;
      width: 60%;
      height: 100%;
      padding: 0.25rem 0.5rem;
      border-left: 1px solid $border;
      display: flex;
      transition: all 0.35s ease-out;
    }

    div span {
      margin: auto;
    }
  }

  &.message-type-cell,
  &.message-text-cell,
  &.message-time-cell {
    border: 1px solid $white;
  }

  &.message-text-cell,
  &.message-time-cell {
    padding: 0.25rem 0.5rem;
  }
}

.import-msg-error td,
.import-msg-error span {
  color: red;
}

.import-msg-row:nth-of-type(odd) {
  .message-type-cell div,
  .message-text-cell,
  .message-time-cell,
  .message-menu-cell {
    background-color: $light-300;
  }
}

.import-msg-row:nth-of-type(even) {
  .message-type-cell div,
  .message-text-cell,
  .message-time-cell,
  .message-menu-cell {
    background-color: $light-200;
  }
}

.import-msg-row:hover {
  .message-type-cell div,
  .message-text-cell,
  .message-time-cell,
  .message-menu-cell {
    background-color: $light-600;
  }
}

.cflow-logistic-page-branch-tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .table {
    width: 100%;
    max-height: 420px;
    overflow: auto;
    border-bottom: 1px solid #dee0e0;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .branch-tree-modal-footer {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .branch-tree-modal-footer_checkox {
    width: 200px;
  }

  .modal-actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    button {
      width: auto;
      margin-left: 10px;
    }
  }
}

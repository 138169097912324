.cflow-division-sub-menu-item {
  position: relative;
  border-bottom: 1px solid $input-bg-hover;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .dropdown {
    width: 100%;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 3rem;
      width: 100%;
      border: none;
      background-color: transparent;

      .icon {
        width: .75rem;
        height: .75rem;
      }
    }

    ul {
      background-color: $white;
      transition: max-height .35s ease-out, background-color .35s ease-out;

      li.cflow-division-sub-sub-menu-item {
        border-bottom: 1px solid $input-bg-hover;
      }

      a {
        p {
          color: $dark-font;
        }

        .content .cflow-icon {
          color: $dark-font;
        }
      }
    }
  }

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 0.75rem 1rem;
    opacity: .7;

    &:hover,
    &.active {
      opacity: 1;
      background-color: $input-bg-hover;

      .content {

        h6,
        p {
          opacity: 1;
        }

        svg {
          fill: $green;
        }
      }
    }
  }

  .drop-down-arrow {
    svg {
      transform: rotate(-90deg);
      transition: transform 0.35s;

      path {
        fill: $dark-font;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;

    h6 {
      color: $dark-font;
      text-transform: uppercase;
    }
  }

  &.collapsed {
    ul {
      max-height: 0;
      overflow: hidden;
    }
  }

  &.opened {
    background-color: $white;

    ul {
      max-height: 400px;
      overflow-y: auto;
    }

    .drop-down-arrow {
      svg {
        transform: rotate(0deg);
        transition: transform 0.35s;
      }
    }

    ul {
      background-color: $drop-down-li-bg;
    }
  }
}
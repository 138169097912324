.cflow-search-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label.component-label {
    font-size: 16px;
  }

  div.search-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #222b45;
    border-radius: 4px;

    input {
      width: 100%;
      padding: 8px 5px;
      font-size: 16px;
      border: none;
    }
    input.selected {
      font-weight: 600;
    }

    div.search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eee;
      height: 100%;
      padding: 0px 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgb(228, 228, 228);
      }
    }
  }

  div.loading-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #fff;
  }
}

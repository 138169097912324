.dashboard-blank-import-data-page,
.dashboard-blank-pin-import-data-page {
  .import-way-data {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .tools {
      align-self: flex-end;
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      column-gap: 1rem;

      button {
        font-size: 1rem;
        padding-top: 10px;
        padding-bottom: 8px;
        white-space: nowrap;
      }
    }

    .data-table-wrapper {
      height: auto;
      margin-bottom: 1rem;

      table {
        display: table;
        position: static !important;
      }
    }
  }
}

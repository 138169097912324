@import '../../../../style/colors.scss';

.file-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .import-file-form {
    display: flex;
    text-align: left;

    .import-file-container {
      flex-grow: 1;
      display: flex;
      column-gap: 1rem; 
    }

    .return-file-container {
      display: flex;

      & > label{
        display: flex;
        align-items: center;
        font-size: smaller;
        font-weight: 100;
        color: #000;
        
        input[type="checkbox"]{
          width: 20px;
          height: 20px;
          margin-right: 0.5rem;
          margin-bottom: 2px;
        }
      }
    }

    .way4-import-buttons {
      display: flex;
      column-gap: 1rem;
      flex-wrap: nowrap;
    }

    .import-button {
      margin-bottom: 1rem;
      width: auto;
      max-height: 2.5rem;
      line-height: .8rem;
      font-size: 1rem;
      background-color: $green-logo;
      color: $white;

      &:hover {
        background-color: $green-hover;
      }
    }

    .import-button.disabled {
      background-color: $white;
      color: $caption;

      &:hover {
        background-color: $white;
        border-color: $border;
      }
    }
    
    .import-file-input {
      position: relative;
      margin-bottom: 1rem;
      max-width: 12rem;
      min-width: 12rem;
      max-height: 2.5rem;
      line-height: .8rem;
      border-color: $green;

      &:hover {
        border-color: $border;
      }
      
      &::before {
        position: absolute;
        z-index: 1;
        content: 'Обрати файли';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        line-height: 2.3rem;
        font-size: 1rem;
        font-weight: 100;
        text-align: center;
        background-color: #fff;
        color: #444;
      }
      
      &:hover::before {
        background-color: #ccc;
      }
    }
  }

  .perso-file {
    align-self: center;
    padding-bottom: 1rem;
    display: flex;

    .perso-file-list {
      margin-left: 1rem;
    }
  }
}

.cflow-oper-day-import-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .item {
        margin: 10px 0;
    }

    input.select-file {
        display: none;
        width: 0;
        height: 0;
    }

    .selected-file-span {
        position: relative;
        margin-left: auto;

        &.hidden {
            visibility: hidden; 
        }

        span {
            font-weight: 600;
        }

        .selected-file-list{
            position: absolute;
            right: 0;
            bottom: 100%;
            padding: .5rem;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba(12, 0, 51, 0.3);
            background-color: #fff;
            opacity: 0;
            transition: .25s ease-out;
        }

        &:hover .selected-file-list {
            opacity: 1;
        }
    }

    label.label-select-file {
        width: 100%;
        background-color: #06503c;
        transition: background-color 0.2s ease;
        color: #fff;
        cursor: pointer;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
        text-align: center;
        border-radius: 5px;
        padding: 10px 0;
        margin-top: 10px;

        &:hover {
            background-color: #043b2d;
        }
    }
}
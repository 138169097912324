@import '../.././../../../style/colors.scss';

.dashboard-users-page {
  .user-ad-info-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;

    & > *:first-child {
      flex-grow: 1;
      margin-bottom: 2rem;
      padding: 1rem;
      max-height: 500px;
      max-width: 100%;
      overflow: auto;
      background-color: $light-200;
    }

    .modal-actions {
      display: flex;
      justify-content: center;

      & > *:first-child {
        margin-right: 1rem;

        &:focus {
          color: $dark-font;
        }
      }

      button {
        width: auto;
      }
    }
  }
}
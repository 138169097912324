.tooltip-modal {
  position: absolute;
  z-index: 100000;
  font-family: 'Open-Sans', sans-serif;
}

@keyframes tooltip_default {
  0% {
    height: 0px;
    padding: 0;
    border: none;
  }
  49% {
    height: 0px;
    padding: 0;
    border: none;
  }
  50% {
    height: 100%;
    padding: 1px 10px;
    border: 1px solid #333;
  }
  100% {
    height: 100%;
  }
}

@keyframes tooltip_unlock {
  0% {
    height: 0px;
    padding: 0;
    border: none;
  }
  49% {
    height: 0px;
    padding: 0;
    border: none;
  }
  50% {
    height: 100%;
    padding: 1px 10px;
    border: 2px solid orange;
  }
  100% {
    height: 100%;
  }
}

@keyframes tooltip_info {
  0% {
    height: 0px;
    padding: 0;
    border: none;
  }
  49% {
    height: 0px;
    padding: 0;
    border: none;
  }
  50% {
    height: 100%;
    padding: 1px 10px;
    border: 1px solid #333;
  }
  100% {
    height: 100%;
  }
}

.cflow-global-tooltip.default {
  display: none;
  background-color: #fff;
  overflow: hidden;
  padding: 1px 10px;
  border: 1px solid #333;
  animation: tooltip_default 1s ease-in-out;
  span {
    font-size: 12px;
  }
}

.cflow-global-tooltip.unlock {
  display: none;
  overflow: hidden;
  padding: 1px 10px;
  animation: tooltip_unlock 1.2s ease-in-out;
  background-color: rgb(255, 237, 203);
  border: 2px solid rgb(255, 189, 67);

  .unlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    padding: 5px 10px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.cflow-global-tooltip.info {
  display: none;
  overflow: hidden;
  padding: 1px 10px;
  animation: tooltip_info 1s ease-in-out;
  background-color: #fff;
  border: 1px solid #333;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    padding: 5px 10px;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.cflow-global-tooltip.opened {
  display: block;
}

@import '../../../style/colors.scss';

.balances-types-dict-balance-operations-page form {
  label {
    color: $dark-font;
    font-weight: normal;
  }
}

.cflow-edit-parameter-form.balances-types-dict-page {
  .cflow-form-section {
    &:last-of-type {
      margin-bottom: 0;
    }

    &.perso-file {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      column-gap: 1rem;

      label {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
      }

      input[type='checkbox'] {
        width: 20px;
        height: 20px;
      }
    }
  }
}

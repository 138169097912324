@import './style/_main';

.cflow-app-wrapper {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-color: $main-bg;
}

.cflow-app-content {
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  margin: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 10px;
}

.DayPicker {
  font-family: 'Futura-PT-Light', sans-serif;
}

.cflow-tooltip-p {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.cflow-items-list-wrapper.active.plate {
  ul {
    max-height: 250px;
    overflow: auto;
    margin-bottom: 6px;
  }
  .cflow-multiselect-item-wrapper {
    position: relative;
    padding-right: 30px;
  }

  .cflow-multiselect-add-more {
    .add-multiselect {
      position: relative;
    }
    .drop-down-arrow {
      position: absolute;
      right: 10px;
      top: 12px;
    }
    &:hover {
      .cflow-tooltip-wrapper {
        display: block;
        white-space: normal;
        z-index: 20;
      }
    }
  }
}

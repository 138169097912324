.cflow-dates-picker {
  width: auto;
  min-width: 150px;
  width: auto;
  max-width: 430px !important;

  label {
    margin-bottom: 0.5rem;
    display: block;
  }

  min-width: 150px;
  width: auto;
  max-width: 390px;

  .cflow-clear-filter {
    position: absolute;
    top: 14%;
    right: 5px;
    padding: 0.25rem;
    margin: 0;
    cursor: pointer;
  }

  .cflow-date-picker-start-date,
  .cflow-date-picker-end-date,
  .cflow-form-wrapper-input {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.25rem;

    svg.data-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: calc(50% - 12px);
      left: 5px;
      z-index: 1;
    }

    input {
      padding: 0.6rem;
      padding-left: 30px !important;
      text-transform: none;
      background-color: transparent;
      color: #222b45;
      border: 1px solid #222b45;
      border-radius: 0.25rem;
      font-size: 15px;
    }
  }

  .cflow-date-picker-dates-wrapper {
    display: flex;
    width: 100%;

    .cflow-date-picker-start-date {
      margin: 0;
    }

    .DayPicker {
      position: absolute;
      top: 100%;
      z-index: 5;

      .CalendarMonth_caption,
      .DayPicker_weekHeader_li,
      .CalendarDay {
        font-family: 'Futura-PT-Light', sans-serif;
      }
    }
  }

  input {
    width: 100%;
  }

  .cflow-date-picker-dates-wrapper div.cflow-date-picker-start-date .cflow-form-wrapper-input,
  .cflow-date-picker-dates-wrapper div.cflow-date-picker-end-date .cflow-form-wrapper-input {
    position: relative;
    transition: all 0.35s ease-out;
  }

  .cflow-form-wrapper-input:hover input {
    background-color: #ccc;
    border-color: #aaa;
  }
}

@import '../../style/colors.scss';

// dropdown
.cflow-drop-down-modal {
  position: absolute;
  z-index: 100000;
  font-family: 'Open-Sans', sans-serif;

  .cflow-dropdown-options-wrapper {
    z-index: 10;
    transition: max-height 0.4s ease,
      box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
  }
  .cflow-dropdown-options-wrapper.collapsed {
    height: 0px;
  }
  .cflow-dropdown-options-wrapper.opened {
    -webkit-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    -moz-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
  }

  .cflow-dropdown-options-wrapper .left {
    position: absolute;
    left: 0;
  }

  .cflow-dropdown-options-wrapper .right {
    position: absolute;
    right: 0;
  }

  .cflow-dropdown-opt {
    width: auto;
    background-color: #fff;
    border: 1px solid #e4e9f2;
    min-width: 130px;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

    .cancel {
      color: brown;
      font-weight: 600;
    }

    li {
      padding: 4px 5px 4px 12px;
      text-transform: capitalize;
      border-bottom: 1px solid #e4e9f2;
      border-left: 3px solid transparent;
      text-overflow: ellipsis;
      white-space: wrap;
      overflow: hidden;
      white-space: nowrap;

      > span {
        font-size: 12px;
        margin-left: 10px;
        color: #222b45;
      }

      p {
        line-height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        border-left: 3px solid #004b46;
        font-weight: bold;
      }

      &.disabled {
        background-color: rgb(212, 212, 212);
        cursor: not-allowed;
      }

      &.disabled:hover {
        background-color: rgb(212, 212, 212);
        cursor: not-allowed;
      }

      &:hover {
        cursor: pointer;
        background-color: #cee7e7;
      }

      .plastic-type-li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        text-overflow: ellipsis;

        span.bin-code {
          font-weight: 700;
          text-overflow: ellipsis;
          flex-basis: 50px;
        }

        span.plastic_type_name {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }
      }
    }
  }

  .cflow-dropdown-search-wrapper {
    padding: 10px;
    border-radius: 0;
    border: none;
    background-color: transparent;

    input {
      width: 100%;
      border: none;

      &::placeholder {
        color: #9fabbf;
      }
    }
  }
}

// multiselect

.cflow-drop-down-modal {
  position: absolute;
  z-index: 100000;
  font-family: 'Open-Sans', sans-serif;

  .cflow-multiselect-options-wrapper {
    z-index: 10;

    &.collapsed {
      display: none;
    }
  }

  .cflow-multiselect-options-wrapper .left {
    position: absolute;
    left: 0;
  }

  .cflow-multiselect-options-wrapper .right {
    position: absolute;
    right: 0;
  }

  .cflow-multiselect-opt {
    position: absolute;
    width: auto;
    background-color: #fff;
    border: 1px solid #e4e9f2;
    min-width: 130px;
    z-index: 2;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    .cancel {
      color: brown;
      font-weight: 500;
      transition: background-color 0.05s ease;
    }
    .cancel:hover {
      background-color: rgb(255, 233, 236) !important;
    }

    .choose-all {
      color: forestgreen;
      font-weight: 500;
      transition: background-color 0.05s ease;
    }
    .choose-all:hover {
      background-color: rgb(202, 255, 202) !important;
    }

    li {
      padding: 4px 5px 4px 12px;
      text-transform: capitalize;
      border-bottom: 1px solid #e4e9f2;
      border-left: 3px solid transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: flex-start;

      input {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        flex-shrink: 0;
      }

      > span {
        font-size: 15px;
        margin-left: 10px;
        color: #222b45;
      }

      span.second-label {
        font-weight: 600;
      }

      span.first-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        border-left: 3px solid #004b46;
        font-weight: bold;
        background-color: #cee7e7;
      }

      &:hover {
        cursor: pointer;
        background-color: #cee7e7;
      }

      .plastic-type-li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 5px;
        text-overflow: ellipsis;

        span.bin-code {
          font-weight: 700;
          text-overflow: ellipsis;
        }

        span.plastic_type_name {
          font-size: 14px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
    }
  }

  .cflow-multiselect-search-wrapper {
    padding: 10px;
    border-radius: 0;
    border: none;
    background-color: transparent;

    input {
      width: 100%;
      border: none;

      &::placeholder {
        color: #9fabbf;
      }
    }
  }

  // search-list
  div.loading-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    -moz-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
  }
  div.search-list-items {
    overflow: auto;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    -moz-box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);
    box-shadow: 5px 5px 2px -1px rgba(34, 60, 80, 0.38);

    div.item {
      padding: 5px 12px;
      text-transform: capitalize;
      border-bottom: 1px solid #e4e9f2;
      border-left: 3px solid transparent;
      text-overflow: ellipsis;
      white-space: wrap;
      overflow: hidden;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      > div {
        span {
          text-overflow: ellipsis;
          white-space: wrap;
          overflow: hidden;
          white-space: pre-wrap;
        }
        span.caption {
          font-weight: 500;
          font-size: 12px;
          margin-right: 10px;
        }
        span.name,
        span.branch {
          font-weight: 600;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: #cee7e7;
      }
    }
  }
}

.cflow-dropdown-wrapper {
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

.cflow-dropdown-toggle-btn {
  width: 100%;
  display: flex;
  align-items: center;

  .black-button {
    color: #222b45 !important;
    border: 1px solid #222b45 !important;
    border-radius: 0.25rem !important;
    padding: 0.4rem;
    height: auto !important;
    transition: border 0.3s ease, background-color 0.3s ease;

    p.placeholder {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      text-transform: lowercase;
    }

    &.invalid {
      border: 1px solid #f00 !important;
    }

    &:hover:not(.invalid) {
      border: 1px solid #888ea0 !important;
    }
  }

  label {
    display: block;
    width: 30%;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .big-label {
    margin-bottom: 0.5rem;
    display: block;
    width: 100% !important;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding: 4px 8px;
    width: 100%;
    height: 32px;
    border-radius: 0;
    border: 1px solid #e4e9f2;
    background-color: transparent;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(206, 203, 203, 0.281);
      border: 1px solid #e4e9f2;
    }

    .cflow-dropdown-caption {
      display: flex;
      margin-left: 10px;
      align-items: center;
      width: 96%;
      overflow: hidden;

      .placeholder {
        color: #9fabbf;
        font-size: 12px;
        max-width: 85%;
      }

      svg {
        margin-right: 5px;
        transform: scale(0.8);
      }

      p {
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.5rem;
        color: #222b45;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.blank-logistic-batch-page-form-batches-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    border: 1px solid #aaa;
    border-radius: 5px;
  }
}
@import '../../../../style/colors.scss';

.dashboard-branches-page {
  min-height: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  & > div:not([class='cflow-modal-window-wrapper']) {
    padding: 1rem;
    background-color: $white;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  & .cflow-modal-window.add-new-transit-point-page {
    width: 50%;
    max-width: 60%;
    min-width: 800px;
    max-height: 800px;
  }

  .current-branch {
    background-color: $white;
    height: auto;

    form {
      flex-direction: column;
      align-items: stretch;

      .checkbox {
        label {
          padding-top: 2px;
          padding-left: 0.5rem;
        }
      }

      input[type='checkbox'] {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .cflow-dropdown-wrapper {
      .cflow-dropdown-toggle-btn {
        display: flex;

        button {
          min-width: 20rem;
        }
      }
    }

    label {
      min-width: 5rem;
      max-width: 5rem;
      padding: 0 0.5rem;
      text-align: right;
    }

    .checkbox {
      margin-left: 1rem;

      label {
        min-width: 11rem;
        max-width: 11rem;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
      }
    }

    #branchName,
    #branchDistrict,
    #branchAddress,
    #branchNameAD,
    #branchCodeAD {
      width: 20rem;
    }

    #branchMFO,
    #branchEmbCode {
      width: 7rem;
    }

    #branchCodeflex {
      width: 14rem;
    }
  }

  .toolbar {
    display: flex;
    margin-top: auto;
    padding-top: 1rem;

    button {
      width: auto;
    }
  }

  .transit-points {
    .toolbar {
      margin-top: 1rem;

      button:not(:last-child) {
        margin-right: 1rem;
      }
    }

    table {
      margin-top: 1rem;
    }
  }

  .branches {
    display: flex;
    flex-direction: column;

    .item-group {
      margin-bottom: 1rem;
    }

    table {
      border-bottom: 1px solid $main-bg;

      th {
        padding: 10px 20px;
        white-space: nowrap;
      }
    }

    .branches-data {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & > *:first-child {
        flex-grow: 1;
      }
    }

    .toolbar {
      justify-content: space-between;

      .actions {
        display: flex;
        flex-wrap: nowrap;

        & > *:first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  .cflow-tooltip-wrapper {
    display: none;
  }

  .cflow-icon-btn {
    position: relative;

    &:hover {
      .cflow-tooltip-wrapper {
        display: block;
      }
    }
  }

  .cflow-button-details {
    font-size: 2rem;
    color: $green-logo;
    cursor: pointer;

    &:hover {
      color: $green-hover;
    }

    &.hidden {
      transform: rotate(180deg);
    }
  }

  .transit-points-data {
    table {
      th {
        padding: 10px 20px;
        white-space: nowrap;
      }
    }
  }

  .animated-data-enter {
    height: 0;
    opacity: 0;
    transition: 0.35s ease-out;
  }

  .animated-data-enter-active {
    height: auto;
    opacity: 1;
  }

  .animated-data-exit {
    height: auto;
    opacity: 1;
    transition: height 0.1s ease-out;
  }

  .animated-data-exit-active {
    height: 0;
    opacity: 0;
  }

  .item-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h2 {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      span.caption {
        font-size: inherit;
        font-weight: inherit;
      }

      span.description {
        font-size: 16px;
      }
    }

    &.command {
      .item-group-item:last-child {
        margin-left: auto;

        *:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .item-group-item {
      display: flex;
      align-items: center;
      margin: 1rem 0 0 1rem;

      .waiter-form-element-wrapper {
        margin: 0;
        padding: 0;
        width: auto;

        .cflow-label {
          margin-right: 0;
        }

        .waiter-form-wrapper-input {
          display: flex;
          align-items: center;
        }

        &.invalid {
          .cflow-dropdown-toggle-btn {
            button {
              border: 1px solid $input-border-invalid;
            }
          }
        }
      }
    }
  }
}

@mixin default_button {
  width: 100%;
  padding: 0.8125rem 1.125rem;
  line-height: 1.25rem;
  cursor: pointer;
  text-transform: capitalize;
  background-color: $green;
  border: 0.0625rem solid $green;
  border-radius: 0.25rem;
  transition: all 0.35s;

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #ffffff;
  }

  &.primary {
    background-color: $primary-500;
  }

  &.cancel {
    background-color: $light-400;
    color: $dark-font;
  }

  &.add-multiselect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    text-transform: none;
    text-align: left;
    background-color: transparent;
    color: $dark-font;

    p {
      white-space: nowrap;
      margin-right: 0.5rem;
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:disabled {
    cursor: not-allowed;
    touch-action: none;
    pointer-events: none;
    background-color: rgba(143, 155, 179, 0.24);
    border-color: rgba(143, 155, 179, 0.24);
    color: rgba(143, 155, 179, 0.48);

    p,
    span {
      color: rgba(143, 155, 179, 0.48);
    }
  }
}

@import '../../../../style/colors.scss';

.no-data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;

  .no-data-component {
    padding: 3rem;
    text-align: center;
    text-indent: 0;
    font-weight: bold;
    color: $neutral-700;
  }
}
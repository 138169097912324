.logistic-page-setup-branch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .select-batch {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 16px;
  }

  > .total-selected {
    margin-top: 10px;
    align-self: flex-end;
    margin-right: 10px;
  }

  .table {
    width: 100%;
    height: 220px;
    overflow: auto;
    border-bottom: 1.5px solid #aaa;

    table {
      width: 100% !important;
    }

    th.th-select-all {
      height: 82px !important;

      input {
        flex-shrink: 0;
      }
    }
  }

  .footer {
    width: 100%;
    margin: 5px 0;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    div.column {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 10px;

      div.item {
        width: 100%;
      }
    }

    input[type='radio'] {
      width: 15px !important;
      height: 15px !important;
    }

    span {
      font-size: 16px;
    }
  }

  .delivery-point {

    .delivery-point-title {
      font-weight: bold;
    }

    display: flex;
    column-gap: 1rem;
    width: 100%;
    padding: 1rem 0 0;
    text-align: left;
  }
}

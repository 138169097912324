.cflow-dropdown-wrapper {
  position: relative;
}

.cflow-dropdown-toggle-btn {
  display: flex;
  align-items: center;

  label {
    display: block;
    width: 30%;
    margin-bottom: 0;
    margin-right: 10px;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding: 4px 8px;
    width: 100%;
    height: 32px;
    border-radius: 0;
    border: 1px solid $border;
    background: transparent;

    .cflow-dropdown-caption {
      display: flex;
      margin-left: 10px;
      align-items: center;
      width: 96%;
      overflow: hidden;

      svg {
        margin-right: 5px;
        transform: scale(0.8);
      }

      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: left;
        color: #222b45;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85%;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:focus {
      border-color: $dark-font;
      background-color: $input-bg-hover;

      svg {
        path {
          fill: $dark-font;
        }
      }
    }
  }
}

.cflow-dropdown-search-options-wrapper {
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 10;

  &.collapsed {
    height: 0px;
  }

  .cflow-dropdown-options {
    position: absolute;
    background-color: $white;
    border: 1px solid $border;
    min-width: 100%;
    width: auto;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 0 15px #222b45;
    
    &.open-upward {
      transform: translateY(calc(-100% - 42px));
    }

    li {
      padding: 4px 5px 4px 12px;
      text-transform: capitalize;
      border-bottom: 1px solid $border;
      border-left: 3px solid transparent;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.group {
        p {
          font-weight: bold;
        }
        cursor: not-allowed !important;
      }

      &.group-item {
        padding-left: 2rem;
      }
  
      p {
        line-height: unset; //30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
  
      &:last-child {
        border-bottom: none;
      }
  
      &.selected {
        border-left: 3px solid $green;
        font-weight: bold;
      }
  
      &:hover {
        cursor: pointer;
        background-color: $input-bg-hover;
      }
    }
  }
}

.cflow-dropdown-search-wrapper {
  padding: 10px;
  border-radius: 0;
  border: none;
  background-color: transparent;

  input {
    width: 100%;
    border: none;

    &::placeholder {
      color: $dropdown-placeholder;
    }
  }
}

.dashboard-users-page {
  height: 100%;
  
  .cflow-logistic-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > *:last-child {
      flex-grow: 1;
    }

    .cflow-blank-plastic-page-wrapper {
      height: auto !important;

      .search {
        button {
          margin-right: auto;
          background-color: rgb(223, 223, 223);
          border: 1px solid rgb(95, 95, 95);
          color: rgb(63, 58, 58);

          &:hover {
            background-color: #aaa;
            color: #fff;
          }
        }

        .choose-branch-value {
          margin-left: 1.25rem;
        }

        .active-users-subbranches label {
          width: 50%;

          &:first-of-type {
            margin-right: 38px;
          }
        }
      }

      button {
        width: auto;
        padding-top: .5rem !important;
        padding-bottom: .4rem !important;
      }

      .search-buttons {
        display: flex;
        justify-content: center;
        padding: 1rem 0;

        & > *:first-child {
          margin-right: 1rem;
        }
      }

      .users-search {
        width: 50%;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .users-search-item {
          margin-bottom: 5px;

          .cflow-input-wrapper-block {
            display: flex;
            justify-content: space-between;

            label {
              width: 30%;
              min-width: 200px;
            }

            input {
              max-width: 50%
            }
          }
        }
      }

      .users-search.search {
        padding-bottom: 0;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
      }

      .table {
        flex-grow: 1;
        position: relative;
    
        table {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          tr {
            padding: .5rem 1rem;

            th, td {
              padding: inherit;
            }
          }
        }
      }
    }
  }
}
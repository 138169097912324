.cflow-items-list-wrapper{
  &.empty{
    padding: 6px;
  }

  &.plate{
    position: relative;
    //padding-left: 6px;
    //padding-bottom: 6px;
    //border: 1px solid #eaeef1;
    //border-radius: 3px;
    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    li{
      margin: 6px 6px 0 0 ;
      padding: 4px 6px 4px 8px;
      border-radius: 2px;
      border: solid 1px $light-300;
      background-color: $light-100;
      max-width: 90%;
      min-width: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .cflow-multiselect-item-value{
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .cflow-icon-wrapper{
        margin-right: .5rem;
      }
      span strong{
        color: $light-700;
      }
    }

    .cflow-empty-data-placeholder{
      min-height: 100px;
    }

    &.active{
      .cflow-multiselect-item-value{
        span strong{
          color: $dark-300;
          text-transform: capitalize;
        }
      }
    }
  }
}

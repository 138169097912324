@import '../../../style/colors.scss';

.cflow-static-table {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  border-spacing: 0px;
  border-collapse: separate;
  border-top: 0px !important;

  &,
  tr,
  th {
    border: 1px solid #dee0e0;
  }

  .sticky {
    position: sticky;
    top: 0;
  }

  thead {
    width: 100%;
    font-size: 14px;

    tr {
      width: 100%;
      background-color: #06503c;
      color: #fff;

      th {
        padding: 20px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      th.th-select-all {
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        span {
          font-size: 12px;
          color: #fff;
        }
      }

      th.two-rows {
        padding: 0;
        min-width: 500px !important;

        .row {
          padding: 10px;
          width: 100%;
        }

        .second {
          width: 100%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            background-color: #054131;
            padding: 10px;
            width: calc(50% - 1px);
          }
        }
      }

      th.sorting {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
        }

        span {
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          flex-grow: 1;
          text-align: center;
        }

        svg {
          margin-left: 10px;
          width: 20px;
          height: 20px;
          color: #fff;
          opacity: 0;
          transition: transform 0.3s linear, opacity 0.3s linear;
          flex-shrink: 0;
        }

        svg.DESC {
          transform: rotate(0deg);
          opacity: 1 !important;
        }

        svg.ASC {
          transform: rotate(180deg);
          opacity: 1 !important;
        }

        svg.none-sorting {
          // transform: rotate(-90deg);
          display: none;
        }

        &:hover {
          svg {
            opacity: 1;
          }
        }
      }
    }

    tr {
      th.filtering {
        padding: 1px 10px;
        padding-bottom: 6px;
        background-color: #fff;
        border-bottom: 1px solid rgb(219, 219, 219);
        input {
          padding: 3px 3px;
          font-family: 'Open Sans', sans-serif;
          border: 0px;
          border-bottom: 2px solid rgb(219, 219, 219);
          transition: border-bottom 0.3s ease;
        }
        input:focus,
        input:active {
          border-bottom: 2px solid #259275;
        }
      }
    }
  }

  .label-div {
    width: 100%;
    background-color: #06503c;
    text-align: center;
    color: #fff;

    p {
      width: 100%;
    }
  }

  tbody {
    width: 100%;

    tr:nth-child(2n + 2) {
      background-color: #eef1f5;
    }

    tr {
      width: 100%;

      th {
        padding: 20px 10px;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 14px;
        transition: all 0.2s ease;

        vertical-align: center;

        .cflow-dropdown-wrapper {
          margin: 0 auto !important;
        }

        .cflow-number-orders {
          width: 100%;

          .cflow-form-wrapper-input {
            width: 100%;

            input {
              width: 100%;
              min-width: 0px;
            }
          }
        }
      }

      th.small {
        min-width: 100px;
      }

      th.small-strict {
        max-width: 100px;
      }

      th.medium {
        min-width: 200px;
      }

      th.medium-strict {
        min-width: 200px;
        max-width: 100px;
      }

      th.big {
        min-width: 300px;
      }

      th.auto-width {
        min-width: max-content;
        white-space: nowrap;
      }

      th.two-rows {
        padding: 0;
        min-width: 500px !important;
        display: flex;

        > div {
          padding: 10px;
          width: calc(50% - 1px);
        }

        .row {
          padding: 10px;
          width: 100%;
        }

        .second {
          width: 100%;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            background-color: rgb(207, 207, 207);
            padding: 10px;
            width: calc(50% - 1px);
          }
        }
      }

      th.parent_operation_id {
        text-align: left;
        span {
          font-weight: 400;
        }
      }

      th.children_operation_id {
        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            width: 15px;
            margin-right: 8px;
            flex-shrink: 0;
          }

          span:not(.small) {
            margin-right: 5px;
          }

          span.small {
            font-size: 10px;
            margin-left: auto;
            align-self: flex-start;
            color: #aaa;
          }
        }
      }
      th.children_operation_id.second-padding {
        div {
          padding-left: 4rem;
        }
      }
      th.children_operation_id.third-padding {
        div {
          padding-left: 8rem;
        }
      }
      th.children_operation_id.fourth-padding {
        div {
          padding-left: 12rem;
        }
      }

      &.pck {
        background-color: $pastel-yellow;
      }
    }

    .selecting {
      transition: background-color 0.2s ease;
      cursor: pointer;
    }

    .selecting:hover {
      background-color: #dff3f3;
    }

    .selected {
      background-color: #cee7e7 !important;
      border: 1px solid #cee7e7 !important;

      th {
        border: 1px solid #01e2e2 !important;
      }
    }
    .selected:hover {
      background-color: #cee7e7;
    }

    .blocked {
      cursor: not-allowed;
      background-color: rgb(201, 199, 199);
    }

    .danger {
      background-color: rgb(252, 243, 243);
      color: rgb(214, 55, 55) !important;
    }

    th.three-dots-th-style {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 50%;
          height: 50%;
        }

        cursor: pointer;
        border-radius: 50%;
      }
    }

    .movement-plastic-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: white;
        margin-left: 5px;
        position: relative;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          .cflow-tooltip-wrapper {
            display: block;
            white-space: normal;
            z-index: 20;
          }
        }
      }

      button.delete {
        svg {
          color: brown;
        }
      }

      button.edit {
        svg {
          color: orange;
        }
      }

      button.confirm {
        svg {
          color: forestgreen;
        }
      }

      button.disabled {
        cursor: not-allowed;

        svg {
          color: #eee;
        }

        &:hover {
          .cflow-tooltip-wrapper {
            display: block;
            white-space: normal;
            z-index: 20;
            background-color: #eee;

            p {
              color: black;
            }
          }
        }
      }
    }
  }
}

.cflow-static-table.blank-delivery-page-create-blank-global,
.cflow-static-table.blank-delivery-page-add-batch-to-package {
  border-bottom: 1px solid #06503c;

  th.two-rows {
    > div {
      padding: 20px 10px;
    }
  }
}

.cflow-static-table.plastic1 {
  min-width: 1100px;
}

.cflow-static-table.logistic-page-main,
.cflow-static-table.logistic-batch-page-main,
.cflow-static-table.logistic-batch-page-form-package-batches,
.cflow-static-table.package-receive-page-main,
.cflow-static-table.logistic-batch-page-main {
  thead {
    tr {
      th.th-select-all {
        height: 85px;
      }
      th {
        padding: 3px 10px;
      }
    }
  }

  tbody {
    tr {
      th {
        padding: 10px;
      }
    }
  }
}

.cflow-static-table.logistic-page-branch-table,
.cflow-static-table.adjust-blank-details,
.cflow-static-table.create-adjust-select-tarif {
  thead {
    tr {
      th {
        padding: 10px 10px;
      }
    }
  }

  tbody {
    tr {
      th {
        padding: 10px;
      }
    }
  }
}

.cflow-static-table.balance-oper-day-table {
  thead {
    tr {
      th {
        span {
          font-size: 13px !important;
        }
        padding: 5px;
      }
    }
  }

  tbody {
    tr {
      th {
        padding: 8px;
      }
    }
  }
}

.cflow-static-table.logistic-page-main,
.cflow-static-table.logistic-page-branch-table {
  tbody {
    tr {
      th {
        padding: 4px 8px;
        font-size: 13px;
      }
    }
  }
}

.cflow-static-table.role-page-role-operations,
.cflow-static-table.logistic-page-branch-table,
.cflow-static-table.package-receive-page-main,
.cflow-static-table.logistic-batch-page-main {
  thead {
    * {
      font-size: 12px;
      font-weight: normal;
    }
    tr {
      th {
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      th {
        padding: 5px;
      }
      th.checkbox-container {
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #eee;
          cursor: pointer;
        }
      }
    }
  }
}

.cflow-static-table.adjust-blank-details {
  * {
    font-size: 12px;
  }
}

table.blank-delivery-page-create-blank-global {
  tbody {
    th {
      .cflow-dropdown-wrapper {
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }
}

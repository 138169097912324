@import '../../../style/colors.scss';

.dashboard-history-page,
.dashboard-item-history-page {
  height: 100%;

  .item-history {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
  
    .item-history-filter {
      background-color: $white;
  
      & > * {
        background-color: $white;
        margin: 0;
      }
    
      & > *:last-child {
        flex-basis: 100%;
      }
    }
  
    & > * {
      padding: 1rem 1rem 0;
      background-color: $white;
      margin: 0;
    }
  
    & > *:first-child {
      padding-top: 0;
    }
  
    & > *:last-child {
      padding-bottom: 1rem;
      flex-basis: 100%;
    }
  
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  
  .item-history-filter-form {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
  
    button.cflow-button-contained {
      align-self: flex-end;
      width: auto;
      min-width: 7rem;
      max-height: 2.5rem;
      margin-bottom: 1rem;

      &:focus {
        outline: 5px solid $input-bg-hover;
      }
    }
  
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  
    .item-history-filter-options-wrapper {
      display: flex;
      flex-direction: column;
  
      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      .cflow-dropdown-options {
        max-height: 375px !important;
      }
    }
  
    .item-history-type-wrapper {
      display: flex;
      align-items: center;
      font-size: 1rem;
  
      & > label {
        display: block;
        margin: .5rem 0;
        padding-left: .5rem;
        width: 5rem;
        font-weight: 100;
        font-size: 1rem;
        color: $dark-font;
        text-align: right;
      }
  
      .cflow-dropdown-toggle-btn button{
        max-height: 2.5rem;
        border-color: $green;
        border-radius: 5px;
      }
  
      & > *:not(:last-child) {
        margin-right: 1rem;
      }
  
      & > *:last-child {
        flex-grow: 1;
      }
    }
  }
  
  .item-history-data {
    display: flex;
    flex-direction: column;
  
    p.item-history-entity {
      font-size: smaller;
    }
  
    & > *:last-child {
      flex-grow: 1;
      margin-top: 1rem;
    }
  
    & > *:first-child {
      margin-top: 0;
    }
  }
}